import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppNavService {
  basePath: any = 'https://rst.intellibooks.io';
  cookieValue: any = '';
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.basePath = environment.basePath;

  }


  getHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    this.cookieValue = this.cookieService.get("SESSIONID");
    headers = headers.set("Accept", "application/json");
    headers = headers.set("Content-Type", "application/json");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    if(this.cookieService.get("refresh_token") && this.cookieValue){
        headers = headers.set("Authorization", "Bearer " + this.cookieValue)
      };
    return headers;
  }

  getInitialSetting() {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/InitialSetting`,  {headers} );
  }


  getMenuGroupList(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/MenuGroup`, { headers });
  }

  getMenuPermissionList(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/MenuPermission`, { headers });
  }

  getEnableResturantPos(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/ChechEnableResturantPos`, { headers });
  }

  approveKot(id: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/ApproveKot/${encodeURIComponent(id)}`, { headers });
  }

  getSONotApproved(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/SOnotApprovedWithItems`, { headers });
  }

  getWebsiteInvoice(orderMode: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/getWebsiteInvoice/${encodeURIComponent(orderMode)}`, { headers });
  }

  getUnApprovedReservedTables(): Observable<any> {
    let headers = this.getHeader();
    return this.http.get<any>(`${this.basePath}/api/getUnApprovedReservedTables`, { headers });
  }


  approveWeraKot(id: string | 'null', data: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/approveWeraOrder/${encodeURIComponent(id)}`,
      {
        headers,
        body: JSON.stringify(data)
      });
  }

  rejectWeraKot(id: string | 'null', data: any | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/rejectWeraOrder/${encodeURIComponent(id)}`,
      {
        headers,
        body: JSON.stringify(data)
      });
  }

  deleteSalesOrder(id: string | 'null'): Observable<any> {
    let headers = this.getHeader();
    return this.http.delete<any>(`${this.basePath}/api/SalesOrder/${encodeURIComponent(id)}`
      ,
      {
        headers
      }
    )
  }

  generate_token(code: any): Observable<any> {
    let headers = this.getHeader();
    return this.http.post<any>(`${this.basePath}/api/generate_token`,
      {
        headers,
        body: JSON.stringify(code)
      });
  }

  downloadPdfByTransId(id: any): Observable<Blob> {
    let headers = this.getHeader();

    headers = headers.set("Accept", "application/pdf");
    headers = headers.set("Content-Type", "application/json");
    this.cookieValue = this.cookieService.get("SESSIONID");
    // headers = headers.set("Authorization", "bearer " + this.cookieValue);
    headers = headers.set("Authorization", "Bearer " + this.cookieValue)

    return this.http.get<any>(`${this.basePath}/api/printApprovedTransaction/${encodeURIComponent(id)}`,
      {
        headers,
        responseType: "blob" as 'json',
      });
  }

  private theme = new BehaviorSubject<boolean>(false);
  settheme(n: any) {
    this.theme.next(n);
  }

  OrderRejected(requestBodyy: any) {
    let headers = this.getHeader();
    return this.http
      .put<any>(`${this.basePath}/api/websiteOrderRejected/${encodeURIComponent(requestBodyy.kot_transction_id)}`,requestBodyy,
        {
          headers
        }
      )
  }

  RejectKot(id: string) {
    let headers = this.getHeader();
    return this.http
      .delete<any>(
          `${this.basePath}/api/rejectionKot/${encodeURIComponent(id)}`
        ,
        {
          headers
        }
      ) 
  }

    
  updateMuteBtn(display_trans_no: any, requestBody: any) {
    let headers = this.getHeader();

    return this.http
      .put<any>(
          `${this.basePath}/api/websiteMute/${encodeURIComponent(display_trans_no)}`
        ,requestBody,
        {
          headers
           }
      )
      
  }

  kotApproved(id: any, requestBodyy: any) {
    let headers = this.getHeader();

    return this.http
      .put<any>(
          `${this.basePath}/api/websiteKotApproved/${encodeURIComponent(id)}`
        ,requestBodyy,
        {
          headers,
        }
      )
      
  }

  // app-nav-service.ts
downloadPdf(data: any | null): Observable<any> {
  let headers = this.getHeader();

  headers = headers.set("Accept", "application/pdf");
  headers = headers.set("Content-Type", "application/json");
  
  // Assuming you are storing the session ID as a cookie
  this.cookieValue = this.cookieService.get("SESSIONID");
  headers = headers.set("Authorization", "bearer " + this.cookieValue);

  // Properly structure the options with headers
  const options = {
      headers: headers,
      responseType: 'blob' as 'json'  // Tell HttpClient we expect a binary blob (PDF)
  };

  // Make the POST request with the data and headers/options
  return this.http.post(`${this.basePath}/api/PdfDownload`, data, options);
}


  }
