<div
  class="sidebar-container"
  [ngStyle]="{ 'background-color': sidebarBackgroundColor }"
>
  <!-- Left icon panel -->
  <div
    class="icon-panel"
    [ngStyle]="{
      'background-color': iconPanelBackgroundColor,
      color: iconPanelTextColor
    }"
  >
    <ul class="icon-list">
      <li
        class="icon-list-item"
        [ngStyle]="{
        'background-color':
          selectedMenu === 'rst-dashboard'
            ? selectedIconBackgroundColor
            : 'transparent',
      }"
        (click)="selectedMenu = 'rst-dashboard'"
      >
        <div (click)="navigateTo('rst-dashboard')" title="Dashboard">
          <app-icon
            [iconName]="'Dashboard'"
            [iconColor]="iconColor"
            [iconSize]="iconSize"
          ></app-icon>
        </div>
      </li>
      <li
        *ngFor="let group of menuGroups"
        (click)="selectedMenu = group.menu_group_icon; toggleDropdown(group)"
        [ngStyle]="{
          'background-color': isActiveIcon(group)
            ? selectedIconBackgroundColor
            : 'transparent'
        }"
        class="icon-list-item"
        (click)="toggleSidebar($event)"
        [title]="group.menu_group_name"
      >
        <app-icon
          [iconName]="group.menu_group_icon"
          [iconColor]="iconColor"
          [iconSize]="iconSize"
        ></app-icon>
      </li>
    </ul>

    <div class="settings-icon">
      <a target="_blank" [href]="helpDeskUrl">
        <div class="submenu-list-gap" title="Help Desk">
          <app-icon
            (click)="navigateTo('Report/day-book')"
            [iconName]="'help_center'"
            [iconColor]="iconColor"
            [iconSize]="iconSize"
          ></app-icon>
        </div>
      </a>
      <div class="submenu-list-gap" title="Day Book">
        <app-icon
          (click)="navigateTo('Report/day-book')"
          [iconName]="'day_book'"
          [iconColor]="iconColor"
          [iconSize]="iconSize"
        ></app-icon>
      </div>
      <div class="submenu-list-gap" title="Sales Summary">
        <app-icon
          (click)="navigateTo('Report/sales-summary')"
          [iconName]="'sales_summary'"
          [iconColor]="iconColor"
          [iconSize]="iconSize"
        ></app-icon>
      </div>
      <div class="submenu-list-gap" title="Items">
        <app-icon
          (click)="navigateTo('Master/items')"
          [iconName]="'stack'"
          [iconColor]="iconColor"
          [iconSize]="iconSize"
        ></app-icon>
      </div>
      <div class="submenu-list-gap" title="Application Setting">
        <app-icon
          (click)="navigateTo('Setting/account-map')"
          [iconName]="'application_setting'"
          [iconColor]="iconColor"
          [iconSize]="iconSize"
        ></app-icon>
      </div>
    </div>
  </div>

  <!-- Main sidebar -->
  <div
    class="main-sidebar"
    [ngClass]="{ collapsed: isSidebarCollapsed }"
    [ngStyle]="{
      'background-color': sidebarBackgroundColor,
      color: sidebarTextColor
    }"
  >
    <div class="logo-container">
      <div class="logo" [ngStyle]="{ 'font-size': logoFontSize + 'px' }">
        {{ logo }}
      </div>
    </div>

    <!-- <ul class="menu-list">
      <li>
        <div
          class="menu-item"
          [ngStyle]="{
            'background-color':
              selectedMenu === 'rst-dashboard'
                ? selectedIconBackgroundColor
                : 'transparent',
            'font-weight': selectedMenu === 'rst-dashboard' ? 'bold' : 'normal'
          }"
        >
          <div class="icon-wrapper">
            <div>
              <app-icon
                [iconName]="'Dashboard'"
                [iconColor]="iconColor"
                [iconSize]="iconSize"
              ></app-icon>
            </div>
          </div>

          <div class="menu-text" (click)="navigateTo('rst-dashboard')">
            Dashboard
          </div>
        </div>
      </li>
    </ul> -->

    <ul class="menu-list">
      <li *ngFor="let group of menuGroups">
        <div
          class="menu-item"
          [ngClass]="{ active: group.open }"
          [attr.aria-expanded]="group.open"
          (click)="toggleDropdown(group); selectedMenu = group.menu_group_name"
          [ngStyle]="{
            'background-color':
              selectedMenu === group.menu_group_name
                ? selectedIconBackgroundColor
                : 'transparent',
            'font-weight':
              selectedMenu === group.menu_group_name ? 'bold' : 'normal'
          }"
        >
          <div class="icon-wrapper">
            <app-icon
              [iconName]="group.menu_group_icon"
              [iconColor]="iconColor"
              [iconSize]="iconSize"
            ></app-icon>
          </div>
          <div class="menu-text">{{ group.menu_group_name }}</div>
          <div class="submenu-toggle">
            <span *ngIf="group.menuItem && group.menuItem.length > 0">
              <app-icon
                [iconName]="group.open ? 'Remove' : 'Add'"
                [iconColor]="iconColor"
                [iconSize]="20"
              ></app-icon>
            </span>
          </div>
        </div>

        <ul *ngIf="group.open && group.menuItem" class="submenu-list">
          <li
            *ngFor="let child of group.menuItem"
            (click)="
              toggleSidebar($event, true);
              navigateTo(child.route);
              selectedMenu = child.route
            "
          >
            <div
              class="sub-item"
              [ngStyle]="{
                'font-size': submenuFontSize + 'px',
                color: submenuTextColor,
                
              }"
            >
              <div [innerHTML]="getIcon('chevron')"></div>
              {{ child.menu_name }}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>

  <!-- Toggle Icon on the right side -->
  <div
    class="toggle-sidebar-icon"
    *ngIf="!isSidebarCollapsed"
    (click)="toggleSidebar($event)"
  >
    <app-icon
      [iconName]="'Left Arrow'"
      [iconColor]="iconColor"
      [iconSize]="25"
    ></app-icon>
  </div>
</div>
