import {
  Component,
  OnInit,
  ViewChild,
  Output,
  ElementRef,
  EventEmitter,
  HostListener,
  ComponentFactoryResolver,
} from "@angular/core";
import { of } from "rxjs";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatExpansionPanel } from "@angular/material/expansion";
import { MatTabGroup } from "@angular/material/tabs";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { RstKotComponent } from "../rst-kot/rst-kot.component";
import { environment } from "../../../environments/environment";
import { SocketServiceService } from "../../socket-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ItemsDialogComponent } from "../items-dialog/items-dialog.component";
import { ReceiptDialogComponent } from "../receipt-dialog/receipt-dialog.component";
import { TabletransferDialogComponent } from "../tabletransfer-dialog/tabletransfer-dialog.component";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component"; // Import the dialog component
import { CacheServiceService } from "../../cache-service.service";
import { TaxSummaryService } from "../../tax-summary.service";
import { SharedModule } from "../../common.module";
import { RstDashboardService } from "../../_services/rst-dashboard.service";
import { Notyf } from "notyf";
import { CategoryCartComponent } from "../../../stories/stories-module/category-cart/category-cart.component";
import { CategoryCardButtonComponent } from "../../../stories/stories-module/category-card-button/category-card-button.component";
import { SubCategoryCardComponent } from "../../../stories/stories-module/sub-category-card/sub-category-card.component";
import { KotListComponent } from "../../../stories/stories-module/kot-list/kot-list.component";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});
@Component({
  selector: "app-rst-invoice",
  templateUrl: "./rst-invoice.component.html",
  styleUrls: ["./rst-invoice.component.css"],
  standalone: true,
  imports: [
    SharedModule,
    CategoryCartComponent,
    CategoryCardButtonComponent,
    SubCategoryCardComponent,
    KotListComponent,
  ],
})
export class RstInvoiceComponent implements OnInit {
  categoryObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  isEditMode = false;
  editContactId: number | null = null;
  suggestions: any[] = [];
  listNumber: any[] = [];
  contactForm: FormGroup;
  inputValue: string = "";
  selectedNumber: string = "";
  pendingInvoice: boolean = false;
  isEnterKeyHandling: boolean = false;
  showBackButton: any = "false";
  errorMessage: string | null = null;
  showPopup = false;
  kotList: any[] = [];
  orderList: any[] = [];

  @Output() onKotAdd: EventEmitter<any> = new EventEmitter<any>();
  model_kot: any = { trans_date: new Date(), net_amount: 0 };
  validationForm: FormGroup;
  eBillsForm: FormGroup;
  show_allow_login: boolean = false;
  allow_login: boolean = false;
  isQtyFocused: boolean = false;
  isdiscountFocused: boolean = false;
  table_id: any;
  table_name: any;
  tableObj: any;
  currency: any;
  kotArr: any = [];
  KOT_data: any = [];
  itemsArr: any = [];
  model: any = {};
  select_all: boolean = true;
  @ViewChild("f") f: any;
  sgst: any = [];
  cgst: any = [];
  igst: any = [];
  customer_item_rate_applicable: boolean = false;
  use_qty_from_sale_history: boolean = false;
  @ViewChild("s_rate1") s_rate1!: ElementRef;
  @ViewChild("qty1") qty1!: ElementRef;
  @ViewChild("sitm") sitm!: ElementRef;
  @ViewChild("tabGroup", { static: false }) tabGroup!: MatTabGroup;
  width: any = window.innerWidth;
  invoice_no: any;
  contractObj: any;
  ledgerObj: any;
  ledger_state: any;
  transDataObj: any = { items_details: [] };
  items: any = [];
  isAutoFocus: boolean = false;
  refund_amt: any = 0;
  tax: any = [];
  totalAmt: any;
  amount: any;
  totalTax: any;
  totalNetAmt: any;
  roundOffAmt: any;
  roundOffSettingVal: any;
  totalDis: any;
  totalSGST: any;
  totalCGST: any;
  totalIGST: any;
  ledger_id: any;
  sgstUnqPer: any = [];
  igstUnqPer: any = [];
  sign: any;
  dataObj: any = {};
  _generating_sales_order: boolean = false;

  itemControl = new FormControl();
  options: any;
  filteredOptions: Observable<any[]> = new Observable<any>();
  itemObj: any = [];
  allowChangeRate: boolean = false;
  mapArr: any;
  soIdArr: any = [];
  taxSlabList: any;
  checkEnableResturantFlag: boolean = false;
  catArr: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  itemObject: MatTableDataSource<any> = new MatTableDataSource<any>();
  searchText: any;
  activeType: any = false;
  imagedata: any;
  itemArr: any;
  items_data: any = [];
  @ViewChild("yourExpansionPanel") yourExpansionPanel!: MatExpansionPanel;
  @ViewChild("panel") panel!: MatExpansionPanel;
  @ViewChild("panel3") panel3!: MatExpansionPanel;
  @ViewChild("panel4") panel4!: MatExpansionPanel;
  data: any;
  isTransactionGSTslab: boolean = false;
  isServiceCharge: boolean = false;
  serviceChargeValue: any = 0;
  transaction_details: any;
  items_invoice: any = [];
  company: any;
  isCash: boolean = true;
  isSOcount: boolean = false;
  so_count: any;
  isDOcount: boolean = false;
  do_count: any;
  customerRates: any;
  isCredit: any;
  stateName: any;
  place_of_supply = new FormControl();
  sbilling_state = new FormControl();
  shipping_state = new FormControl();
  totalamt: any;
  invoice_arr: any = [];
  webappActiveTable: boolean = false;
  closing_balance1: any;
  closing_balance2: any;
  totalAmtReadonlyFlg: boolean = false;
  receiptdata: any;
  dueInvoice: any;
  displayedColumns = [
    "customer_name",
    "display_trans_no",
    "net_amount",
    "advance",
  ];
  dueInvoiceObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  tableDetails: any;
  selectedTable: any;
  take_away = true;
  dine_in = true;
  water: boolean = false;
  waiter: boolean = false;
  notesValueForEdit: any;
  disposalItemData: any;
  disposallist: any;
  disposalItemlist: any;
  disposalItemToggle = false;
  showValidationInput = false;
  showValidationDiscount = false;
  otpVerified = false;
  otpInvalid = false;
  non_receivable = false;
  formSubmitted = false;
  order_tab: any = "Dine-In";
  sub_category_data: any;
  subCatArr = [];
  getItems: boolean = false;
  getCategory: boolean = false;
  getSubCategory: boolean = false;
  getDisposalitems: boolean = false;
  getDisposalCategory: boolean = false;
  isLocal: boolean = false;
  tableInfo: any;
  tableCapacity: any = 0;
  optionarray_amount: number = 0;
  options_array: any[] = [];
  checkedVariant: any[] = [];
  checkedVariantAmount: number = 0;
  connection: any;
  selectedCategoryName: any = null;
  ebillData: any = [];
  menuItemsWithPriceList: any = [];
  isEbillingEnable: any = "false";
  hasPriceList: any = null;
  waitforinvoice: boolean = false;
  waitforkot: boolean = false;
  waitforTakeaway: boolean = false;
  kot_Dta: any = [];

  constructor(
    private apiService: RstDashboardService,
    private _Activatedroute: ActivatedRoute,
    private socketService: SocketServiceService,
    public dialog: MatDialog,
    private cacheService: CacheServiceService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.currency = environment.currency;
    _Activatedroute.paramMap.subscribe((params) => {
      if (params.get("id")) {
        this.table_id = params.get("id");
      }
    });

    this.validationForm = this.fb.group({
      validationCode: new FormControl(""),
    });

    this.eBillsForm = this.fb.group({
      selectedEbillNames: new FormControl([]),
    });

    this.contactForm = this.fb.group({
      contact_no: ["", [Validators.pattern(/^(\d{10})?$/)]], // 10 digits or empty
    });
    // Initialize filtered options
    this.filteredOptions = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterItems(value))
    );
  }

  ngOnInit() {
    this.connectSocket();
    this.load();

    this.validationForm
      .get("validationCode")
      ?.valueChanges.subscribe((value: string) => {
        if (value && value.length === 5) {
          this.otpVerified = false;
          this.otpInvalid = false;
        } else if (value && value.length === 6) {
          this.verifyEmailOtp(value);
        }
      });
  }

  // reset all data
  reset() {
    this.itemsArr = [];
    this.soIdArr = [];
    this.kotArr = [];
    this.load();
  }

  //getting and returning contact number
  get contact_no() {
    return this.contactForm.get("contact_no");
  }
  // Event emitters for actions (local to the user's session)
  private refreshComponent() {
    this.load(); // Reload the component's data for the current user only
  }

  ngOnDestroy() {
    this.connection.unsubscribe();
  }

  // reset otp field
  resetOTPFields() {
    this.validationForm.reset();
    this.otpVerified = false;
    this.otpInvalid = false;
    this.showValidationDiscount = false;
  }

  onCheckboxChange() {
    if ((this.form.controls["dis_per"] && this.form.controls["dis_per"].value === 0) || null) {
      if (!this.showValidationInput) {
        this.apiService.ncValidation().subscribe(
          (response: any) => {
            this.showValidationDiscount = false;
            this.non_receivable = true;
          },
          (error: any) => {
            console.error("API error:", error);
          }
        );
      } else {
        this.non_receivable = false;
      }
    } else {
      notyf.error("Select either Discount or No Cost");
    }
    this.showValidationInput = !this.showValidationInput;
    this.showValidationDiscount = !this.showValidationDiscount;
    if (!this.showValidationInput) {
      this.resetOTPFields();
    }
  }

  processCategoryList(result: any): void {
    this.catArr = result.filter(
      (category: any) => category.restaurant_menu_items == true
    );
    this.catArr.forEach((category: any) => {
      const cachedImage = this.cacheService.get(
        `categoryImage_${category.category_id}`
      );
      if (cachedImage) {
        this.setImageUrl(category, cachedImage);
      } else {
      }
    });
  }

  setImageUrl(category: any, url: string): void {
    category.imageurl = url;
  }

  connectSocket() {
    this.connection = this.socketService
      .on("refresh")
      .subscribe((result: any) => {
        if (!this._generating_sales_order && this.order_tab !== "Take Away") {
          // get table for the transaction.
          this.apiService.getTableByTransId(result.transaction_id).subscribe(
            (res: any) => {
              if (
                res &&
                res[0] &&
                res[0].table_id &&
                res[0].table_id == this.table_id
              ) {
                this.reset();
              }
            },
            (error) => {
              console.log("refresh socket at rst invoice error", error);
            }
          );
        }
      });
  }

  createImageFromBlob(image: Blob, fn: any) {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        var reader_result = reader.result;
        fn(reader_result);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
  load() {
    (this.isEbillingEnable = localStorage.getItem("isEBillingEnable")),
      (this.eBillsForm = this.fb.group({
        selectedEbillNames: new FormControl([]),
      }));
    // if (this.isEbillingEnable) this.getEbillingdata();

    this.roundOffSettingVal = localStorage.getItem("roundoff");

    // category-----------------------------------------------------------
    const cachedCategory = this.cacheService.get("categoryList");

    if (cachedCategory) {
      this.getCategory = false;
      this.catArr = cachedCategory;
      this.categoryObj = new MatTableDataSource(this.catArr);
      this.categoryObj.sort = this.sort;
      this.categoryObj.paginator = this.paginator;
      // this.processCategoryList(cachedCategory);
    } else {
      this.getCategory = true;
    }

    //DisposalItemList--------------------------------------------------------
    const disposalItemList = this.cacheService.get("disposalItemList");
    if (disposalItemList) {
      this.getDisposalitems = false;
      this.disposalItemData = disposalItemList;
    } else {
      this.getDisposalitems = true;
    }
    //DisposalCategoryList--------------------------------------------------------
    const disposalCategoryList = this.cacheService.get("disposalCategoryList");
    if (disposalCategoryList) {
      this.getDisposalCategory = false;
      this.disposallist = disposalCategoryList;
    } else {
      this.getDisposalCategory = true;
    }

    this.tableInfoFun();
  }

  tableInfoFun() {
    var keyData = {
      roleid: localStorage.getItem("role_id"),
      key: "ChangeMRP",
    };
    const data = [];
    data.push({
      getItems: this.getItems,
      getCategory: this.getCategory,
      getSubCategory: this.getSubCategory,
      getDisposalitems: this.getDisposalitems,
      getDisposalCategory: this.getDisposalCategory,
      keyData: keyData,
    });
    this.spinner.show();
    this.apiService.getTableInfo(this.table_id, data).subscribe(
      async (result: any) => {
        this.spinner.hide();
        this.tableInfo = result[0];
        this.tableCapacity = this.tableInfo.tableCapacity;
        //---------mapArr----------------------------------------------
        if (this.tableInfo.accountMapList) {
          this.mapArr = this.tableInfo.accountMapList;
          if (this.mapArr) {
            this.findAccSetting();
          }
        }
        //---------restaurantMenuCategory------------------------------
        if (this.tableInfo.restaurantMenuCategory !== null) {
          if (this.tableInfo.restaurantMenuCategory.length) {
            this.catArr = this.tableInfo.restaurantMenuCategory;
            this.categoryObj = new MatTableDataSource(this.catArr);
            this.categoryObj.sort = this.sort;
            this.categoryObj.paginator = this.paginator;
            this.cacheService.set("categoryList", this.catArr);
          }
        }

        //---------session---------------------------------------------
        if (this.tableInfo.session !== null && this.tableInfo.session.length) {
          this.show_allow_login = true;

          this.webappActiveTable = this.tableInfo.session[0].active;
          this.water = this.tableInfo.session[0].water;
          this.waiter = this.tableInfo.session[0].waiter;
          this.allow_login = this.tableInfo.session[0].allow_login;
          this.form
            .get("customer_name")
            ?.setValue(this.tableInfo.session[0].name);
          this.form
            .get("contact_no")
            ?.setValue(this.tableInfo.session[0].contact_no);
          this.form.get("gstin")?.setValue(this.tableInfo.session[0].gstin);
          this.form.get("email")?.setValue(this.tableInfo.session[0].email);
          this.form.get("gstin")?.setValue(this.tableInfo.session[0].gstin);
        }
        //---------TableListWithKOT------------------------------------

        const filteredKot = this.tableInfo.kot.filter(
          (item: any) => Object.keys(item).length !== 0
        );
        this.tableObj = filteredKot;
        if (this.tableObj) {
          this.tableObj.forEach((kot: any) => {
            kot.added = true;
            this.soIdArr.push(kot.transaction_id);
            this.kotArr.push(JSON.parse(JSON.stringify(kot)));

            kot.items_details.forEach((item: any) => {
              this.itemsArr.forEach((item: any) => {
                item.qty = parseFloat(item.qty);
                item.amount =
                  parseFloat(item.s_rate + item.optionAmount) *
                  parseFloat(item.qty);
              });

              // var pos = this.itemsArr.findIndex(
              //   (e: any) =>
              //     e.item_id === item.item_id && e.s_rate == item.s_rate && e.amount == item.amount
              // );

              let pos = this.itemsArr.findIndex((search_item: any) => {
                let isChildOptionsMatch = true; // Default to true
                let isVariantsMatch = true; // Default to true

                // Check base item details
                const isBasicMatch =
                  search_item.item_id === item.item_id &&
                  search_item.optionAmount === item.optionAmount &&
                  search_item.checkedVariantAmount ===
                    item.checkedVariantAmount;

                // Check childOptions if they exist
                if (
                  item.optionsData &&
                  item.optionsData.length > 0 &&
                  item.optionsData[0].childOptions
                ) {
                  isChildOptionsMatch = item.optionsData[0].childOptions.every(
                    (childOpt: any, index: number) => {
                      return (
                        search_item.optionsData &&
                        search_item.optionsData.length > 0 &&
                        search_item.optionsData[0].childOptions &&
                        search_item.optionsData[0].childOptions[index] &&
                        search_item.optionsData[0].childOptions[index].title ===
                          childOpt.title
                      );
                    }
                  );
                }

                // Check variant groups if they exist
                if (
                  item.variant_groups &&
                  item.variant_groups.length > 0 &&
                  item.variant_groups[0].variants
                ) {
                  isVariantsMatch = item.variant_groups[0].variants.every(
                    (variant: any, index: number) => {
                      return (
                        search_item.variant_groups &&
                        search_item.variant_groups.length > 0 &&
                        search_item.variant_groups[0].variants &&
                        search_item.variant_groups[0].variants[index] &&
                        search_item.variant_groups[0].variants[index].title ===
                          variant.title
                      );
                    }
                  );
                }

                // Return true if all conditions match
                return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
              });
              
              if (pos == -1) {
                this.itemsArr.push(item);
              } else {
                if(this.itemsArr[pos].s_rate !== item.s_rate){
                  this.itemsArr.push(item)
                  
                }else{
                  this.itemsArr[pos].qty =
                  parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);
                this.itemsArr[pos].amount =
                  parseFloat(this.itemsArr[pos].qty) *
                  parseFloat(
                    this.itemsArr[pos].s_rate + this.itemsArr[pos].optionAmount
                  );
                this.itemsArr[pos].igst_amt =
                  (this.itemsArr[pos].amount * this.itemsArr[pos].igst_per) /
                  100;
                this.itemsArr[pos].cgst_amt =
                  (this.itemsArr[pos].amount * this.itemsArr[pos].cgst_per) /
                  100;
                this.itemsArr[pos].sgst_amt =
                  (this.itemsArr[pos].amount * this.itemsArr[pos].sgst_per) /
                  100;
                }
              }
            });
          });
          this.calculateAmtWithTransTax();
        }

        //---------UnpaidInvoice----------------------------------------
        if (this.tableInfo.unpaidInvoiceList) {
          this.dueInvoice = this.tableInfo.unpaidInvoiceList;
          this.dueInvoiceObj = new MatTableDataSource(this.dueInvoice);
          this.dueInvoiceObj.sort = this.sort;
          this.dueInvoiceObj.paginator = this.paginator;
          if (this.dueInvoice && this.dueInvoice.length > 1) {
            // this.panel4.open();
          }
        } else {
          this.dueInvoiceObj = new MatTableDataSource();
        }

        //--------companyState----------------------------------------
        if (this.tableInfo.companyState) {
          this.company = this.tableInfo.companyState[0];
        }
        //--------companyState----------------------------------------
        if (this.tableInfo.companyState) {
          this.ledgerObj = this.tableInfo.ledgerState[0];
        }
        // -----------------isLocal-----------------
        this.isLocal = this.tableInfo.isLocal;
        localStorage.setItem("isLocal", JSON.stringify(this.isLocal));

        //----------------Disposal------------------------------
        if (this.tableInfo.disposalItemList !== null) {
          if (this.tableInfo.disposalItemList && this.tableInfo.disposalItemList.length) {
            this.disposalItemData = this.tableInfo.disposalItemList;
            this.cacheService.set("disposalItemList", this.disposalItemData);
          }
        }
        if (this.tableInfo.disposalCategoryList !== null) {
          if (this.tableInfo.disposalCategoryList && this.tableInfo.disposalCategoryList.length) {
            this.disposallist = this.tableInfo.disposalCategoryList;
            this.cacheService.set("disposalCategoryList", this.disposallist);
          }
        }

        if (this.tableInfo.ebill !== null) {
          if (this.tableInfo.ebill && this.tableInfo.ebill.length) {
            this.ebillData = this.tableInfo.ebill;
          }
        }

        //---------tableName------------------
        this.table_name = this.tableInfo.tableName
          ? this.tableInfo.tableName
          : null;
        //---------allowChangeRate------------------
        this.allowChangeRate = this.tableInfo.allowChangeRate ? true : false;

        // -----------------has pricelist------------------
        this.hasPriceList = this.tableInfo.hasPriceList;

        // await this.renderItems(this.tableInfo.tableDetails);
      },
      (result: any) => {
        notyf.error("unable to load data");
      }
    );
  }

  renderItems(tableInfo: any) {
    const priceListName =
      tableInfo[0].pricelist_name && tableInfo[0].pricelist_name != null
        ? tableInfo[0].pricelist_name
        : "default";
    const keys: any = [];

    // Iterate over each object in the array
    this.menuItemsWithPriceList.forEach((obj: any) => {
      // Get keys from the current object and push them into the keys array
      const objKeys = Object.keys(obj);
      keys.push(objKeys);
    });

    let selectedItems: any = [];
    keys.forEach((key: any) => {
      if (priceListName === key[0]) {
        selectedItems = this.menuItemsWithPriceList.find(
          (item: any) => item[key[0]]
        );
      }
    });
    // }

    this.itemArr = selectedItems[priceListName];
  }

  handelerror(event: any) {
    if (event.target) {
      event.target.src = "/assets/images/NoImage.png";
    }
  }

  _filter(value: any) {
    const filterValue = typeof value != "object" ? value.toLowerCase() : value;
    return this.itemObj.filter(
      (item: any) =>
        item.item_code.toLowerCase().includes(filterValue) ||
        item.item_name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(Item: any) {
    return Item ? Item.item_name : "";
  }

  search(event: any) {
    // If backspace is pressed and the input is empty
    if (
      event.key === "Backspace" &&
      (!this.itemControl.value || this.itemControl.value?.length === 0)
    ) {
      // Clear the item data and related fields without focusing on the qty input field
      this.resetItemInput();
      return;
    }
    if (event.key != "ArrowDown" && event.key != "ArrowUp") {
      if (this.itemControl && this.itemControl.value && this.itemControl.value.length > 1) {
        const filter = "restaurant_menu_items";
        this.apiService
          .getCustomFilterItemList(
            this.itemControl.value,
            filter,
            this.table_id
          )
          .subscribe(
            (result: any) => {
              this.itemObj = result;
              this.filteredOptions = this.itemControl.valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
              );
            },
            (result: any) => {}
          );
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      }
    }
  }

  itemSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option && event.option.value != 0) {
      var item_id = event.option.value ? event.option.value.item_id : undefined;
      var indexofItem = this.itemObj.findIndex(
        (i: any) => i.item_id === item_id
      );

      if (
        (event.option.value.options && event.option.value.options.length )> 0 ||
        (event.option.value.variant_groups && event.option.value.variant_groups.length )> 0
      ) {
        const data = { item: this.itemObj[indexofItem], status: "add" };
        this.openCustomization(data);
      } else {
        const isCustomised = false;
        this.populateItemData(this.itemObj[indexofItem], isCustomised);
      }

      // Move focus to qty1 element after item selection
      setTimeout(() => {
        if (this.qty1 && this.qty1.nativeElement) {
          this.qty1.nativeElement.focus();
        }
      }, 100);
    }
  }
  clearInput() {
    this.itemControl.reset();
  }

  populateItemData(itemDetails: any, isCustomised: any) {
    this.model = itemDetails;
    const qty = itemDetails.qty ? itemDetails.qty : 1;
    const a = this.model.qty * this.model.s_rate;
    const b = this.model.qty * this.optionarray_amount;
    const amount = a + b;
    this.model.amount = amount ? amount : itemDetails.s_rate * qty;
    this.model.qty = qty;

    this.form.get("item_qty")?.setValue(this.model.qty);
    this.form.get("item_rate")?.setValue(this.model.s_rate);
    this.form.get("item_amt")?.setValue(this.model.amount);

    if (isCustomised) this.onAddItem();
  }

  focusSetting() {
    if (this.isAutoFocus && this.qty1) {
      this.qty1.nativeElement.focus();
    }
  }

  // In onAddItem method, add checks to differentiate between Take Away and Dine-In
  onAddItem() {
    // Ensure that the search field is not blank and the selected item is valid
    if (!this.itemControl?.value) {
      notyf.error("Please select an item.");
      return;
    }
    // Check if there is a valid item to add (price greater than 0 and quantity set)
    
    if (this.model.item_id && this.model.qty && this.model.s_rate > 0) {
      let found_item = null;
      let new_item_Added = false;
      let pos = null;

      if (this.order_tab === "Take Away") {
        pos = this.itemsArr.findIndex(
          (e: any) =>
            e.item_id === this.model.item_id && e.s_rate == this.model.s_rate
        );
      } else {
        pos = this.KOT_data.findIndex(
          (e: any) =>
            e.item_id === this.model.item_id && e.s_rate == this.model.s_rate
        );
      }

      if (pos === -1) {
        found_item = this.model;
        new_item_Added = true;
      } else {
        // Handle existing item update logic for Dine-In and Take Away
        if (this.order_tab === "Dine-In") {
          this.KOT_data[pos].qty =
            parseFloat(this.KOT_data[pos].qty) + parseFloat(this.model.qty);
          this.KOT_data[pos].amount = this.calculateAmount(this.KOT_data[pos]);
           
          if (this.panel) {
            //sentry error solution
            this.panel.open();
          }
        } else if (this.order_tab === "Take Away") {
          this.itemsArr[pos].qty =
            parseFloat(this.itemsArr[pos].qty) + parseFloat(this.model.qty);
          this.itemsArr[pos].amount = this.calculateAmount(this.itemsArr[pos]);
          this.calculateAmtWithTransTax();
        }
      }

      if (new_item_Added) {
        // If it's a new item, add it to the respective list
        if (this.order_tab === "Dine-In") {
          if (found_item.amount > 0) {
            this.KOT_data.unshift(found_item);
            this.panel.open();
          } else {
            notyf.error("you did not enter quantity,Try Again");
          }
        } else if (this.order_tab === "Take Away") {
          this.itemsArr.push(found_item);
          this.calculateAmtWithTransTax();
        }
      }

      // Reset the model and form controls for the next item
      this.resetItemInput();
    } else {
      // Handle invalid item input case
      notyf.error("Item price must be greater than 0.");
    }
  }

  private calculateAmount(item: any): number {
    const baseAmount = parseFloat(item.qty) * parseFloat(item.s_rate);
    const optionsAmount = parseFloat(item.qty) * this.optionarray_amount;
    return baseAmount + optionsAmount;
  }

  private resetItemInput(): void {
    this.model = {}; // Clear the model
    this.form.get("item_qty")?.setValue(null); // Reset the quantity input
    this.form.get("item_rate")?.setValue(null); // Reset the rate input
    this.form.get("item_amt")?.setValue(null); // Reset the amount input
    this.itemControl.setValue("");
    setTimeout(() => {
      if (this.sitm.nativeElement) {
        this.sitm.nativeElement.focus(); // Refocus on the search input
      }
    }, 0);
  }

  private saveInvoiceData(action: string) {
    // Common logic to save invoice data
    this.saveContact();
    if (this.showValidationInput) {
      if (this.validationForm.valid) {
        this.otpVerified = false;
        this.otpInvalid = false;
        this.formSubmitted = true;
        this.showValidationInput = false;
        this.showValidationDiscount = false;
        this.Savedata(action);
      }
    } else {
      this.Savedata(action);
    }
  }

  // all accounts settings
  findAccSetting() {
    var takeAway = this.mapArr.filter((e: any) => {
      return e.key === "Take_Away_Table";
    });
    if (takeAway[0].template_value == this.table_id) {
      (this.dine_in = false), (this.order_tab = "Take Away");
    }

    this.mapArr.filter((e: any) => {
      if (e.key === "Transaction_gstslab") {
        this.isTransactionGSTslab = e.checkbox_value;
      }
    });

    if (this.isTransactionGSTslab) {
      var indexofTrSlabValue = this.mapArr.findIndex(
        (i: any) => i.key === "gstslab_value"
      );

      this.form
        .get("taxslab")
        ?.setValue(this.mapArr[indexofTrSlabValue].template_value);
    }

    this.mapArr.filter((e: any) => {
      if (e.key === "service_charge") {
        this.isServiceCharge = e.checkbox_value;
      }
    });

    if (this.isServiceCharge) {
      var indexofServiceValue = this.mapArr.findIndex(
        (i: any) => i.key === "service_charge_value"
      );

      this.mapArr.filter((e: any) => {
        if (e.key === "service_charge_value") {
          this.serviceChargeValue = e.text_value;
        }
      });
    
      this.form
        .get("service_charge_per")
        ?.setValue(this.mapArr[indexofServiceValue].text_value);

    }

    var indexofautofocus = this.mapArr.findIndex(
      (i: any) => i.key === "Enable_Auto_Focus"
    );

    if (this.mapArr[indexofautofocus].checkbox_value) {
      this.isAutoFocus = true;
    }
  }

  // calculation and deductions
  calculateAmtWithTransTax() {
    
    if (this.isTransactionGSTslab === false) {
      this.amount = 0;

      var taxable_amt = 0,
        tax_amt = 0,
        sgst_per = 0,
        sgst_amt = 0,
        cgst_per = 0,
        cgst_amt = 0,
        igst_per = 0,
        igst_amt = 0,
        vat_per = 0,
        vat_amt = 0,
        service_charge = 0;
      this.sign = null;
      this.totalAmt = 0;
      this.totalDis = 0;
      this.amount = 0;
      for (var i = 0; i < this.itemsArr?.length; i++) {
        this.itemsArr[i].amount = parseFloat(this.itemsArr[i].amount);
        this.amount += parseFloat(this.itemsArr[i].amount);
        if (this.itemsArr[i].tax_mode === "GST") {
          sgst_amt += this.itemsArr[i].sgst_amt || 0;
          cgst_amt += this.itemsArr[i].cgst_amt || 0;
          igst_amt += this.itemsArr[i].igst_amt || 0;
        } else {
          vat_amt += this.itemsArr[i].vat_amt || 0;
        }
      }

      taxable_amt = this.amount - this.form.value.discount_amt;
        
      //   service_charge = (taxable_amt * this.serviceChargeValue) / 100;
      //   this.form.get("service_charge_per")?.setValue(this.serviceChargeValue);
      //   this.form.get("service_charge")?.setValue(service_charge);
      // }else
     

      this.totalTax = parseFloat(
        (sgst_amt + cgst_amt + igst_amt + vat_amt).toFixed(2)
      );
      // const discountPer = this.form.get("dis_per")?.value || 0;
      // if (discountPer > 0) {
      //   const disAmt = (this.totalTax * discountPer) / 100;
      //   this.totalTax = this.totalTax - disAmt;
      // }

      const discountPer = this.form.get("dis_per")?.value || 0;
      if (discountPer > 0) {
        const disAmt = (this.totalTax * discountPer) / 100;
        const totalTaxAmount:any = this.totalTax - disAmt;
        this.totalTax = parseFloat(totalTaxAmount).toFixed(2)
      }


      const serviceChargePer = this.form.get("service_charge_per")?.value || 0;
      if (serviceChargePer > 0) {
        service_charge = (this.amount * serviceChargePer) / 100;
        this.form.get("service_charge")?.setValue(service_charge);
      }

      this.totalAmt = parseFloat(
        (
          (taxable_amt || 0) +
          (this.totalTax || 0) +
          (this.form.value.other1_amt || 0) +
          (this.form.value.other2_amt || 0) +
          (service_charge || 0)
        ).toFixed(2)
      );

      this.totalAmt = Math.round(this.totalAmt * 100) / 100;
      this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      if (this.roundOffSettingVal == 2) {
        // Round after two decimal places
        this.totalNetAmt = Math.round(this.totalAmt * 100) / 100; // Round to two decimal places
        this.roundOffAmt =
          Math.round((this.totalNetAmt - this.totalAmt) * 100) / 100; // Calculate round-off amount
      } else if (this.roundOffSettingVal == 1) {
        // Round after one decimal place
        this.totalNetAmt = Math.round(this.totalAmt * 10) / 10; // Round to one decimal place
        this.roundOffAmt =
          Math.round((this.totalNetAmt - this.totalAmt) * 10) / 10; // Calculate round-off amount
      } else {
        // General round-off calculation
        if (this.roundOffAmt < 0.5) {
          this.totalNetAmt = this.totalAmt - this.roundOffAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.roundOffAmt = -this.roundOffAmt;
          this.sign = "fa-minus";
        } else {
          this.roundOffAmt = 1 - this.roundOffAmt;
          this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
          this.totalNetAmt = this.totalAmt + this.roundOffAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.sign = "fa-plus";
        }
      }

      // this.form.get("service_charge")?.setValue(service_charge);
      this.form.get("total_amount")?.setValue(this.amount);
      this.form.get("net_amount")?.setValue(this.totalNetAmt);
      this.form.get("roundoff_amt")?.setValue(this.roundOffAmt);
      this.form.get("gross_amount")?.setValue(this.totalAmt);
      this.form.get("total_tax")?.setValue(this.totalTax);
      this.form.get("sgst_per")?.setValue(sgst_per);
      this.form.get("sgst_amt")?.setValue(sgst_amt);
      this.form.get("cgst_per")?.setValue(cgst_per);
      this.form.get("cgst_amt")?.setValue(cgst_amt);
      this.form.get("igst_per")?.setValue(igst_per);
      this.form.get("igst_amt")?.setValue(igst_amt);
      this.form.get("vat_per")?.setValue(vat_per);
      this.form.get("vat_amt")?.setValue(vat_amt);
      this.form.get("taxable_amt")?.setValue(taxable_amt);
      this.refreshPayment();
    } else {
      this.amount = 0;
      var tax_slab = this.form.value.taxslab;
      // var service_charge = this.form.value.serviceCharge;

      var taxable_amt = 0,
        tax_amt = 0,
        sgst_per = 0,
        sgst_amt = 0,
        cgst_per = 0,
        cgst_amt = 0,
        igst_per = 0,
        igst_amt = 0,
        service_charge = 0;

      this.sign = null;
      this.totalAmt = 0;
      this.totalDis = 0;
      this.amount = 0;
      let vatAmount = 0;
      let vatTaxAmount = 0;
      for (var i = 0; i < this.itemsArr?.length; i++) {
        // service_charge_amount = (this.itemsArr[i].amount * service_charge) /100

        if (this.itemsArr[i].tax_mode === "GST") {
          this.amount = this.amount + this.itemsArr[i].amount;
        } else {
          this.itemsArr[i].vat_per = this.itemsArr[i].taxslab;

          this.itemsArr[i].vat_amt =
            this.itemsArr[i].amount * (this.itemsArr[i].vat_per / 100) || 0;

          this.itemsArr[i].vat_amt =
            Math.round(this.itemsArr[i].vat_amt * 100) / 100;

          this.itemsArr[i].total_tax = parseFloat(this.itemsArr[i].vat_amt) || 0;

          this.itemsArr[i].total =
            this.itemsArr[i].amount + this.itemsArr[i].total_tax;

          vatTaxAmount += this.itemsArr[i].total_tax;

          vatAmount += this.itemsArr[i].amount;
        }
      }
      taxable_amt = this.amount - this.form.value.discount_amt;

      // if (this.isServiceCharge) {
      //   service_charge = (taxable_amt * this.serviceChargeValue) / 100;
      //   this.form.get("service_charge_per")?.setValue(this.serviceChargeValue);
      //   this.form.get("service_charge")?.setValue(service_charge);
      // }

      const serviceChargePer = this.form.get("service_charge_per")?.value || 0;
      if (serviceChargePer > 0) {
        service_charge = (this.amount * serviceChargePer) / 100;
        this.form.get("service_charge")?.setValue(service_charge);
      }


      sgst_per = tax_slab / 2;
      sgst_amt = taxable_amt * (sgst_per / 100);
      sgst_amt = Math.round(sgst_amt * 100) / 100;
      cgst_per = tax_slab / 2;
      cgst_amt = taxable_amt * (cgst_per / 100);
      cgst_amt = Math.round(cgst_amt * 100) / 100;

      tax_amt = sgst_amt + cgst_amt + igst_amt;
      this.totalTax = parseFloat(
        (sgst_amt + cgst_amt + igst_amt + vatTaxAmount).toFixed(2)
      );

      this.totalAmt =
      parseFloat((taxable_amt || 0) +
        (vatAmount || 0 )+
        (tax_amt || 0) +
        (vatTaxAmount || 0) +
        (this.form.value.other1_amt || 0) +
        (this.form.value.other2_amt || 0) +
        (service_charge || 0)).toFixed(2);
        
      // service_charge_amount;
      this.totalAmt = Math.round(this.totalAmt * 100) / 100;
      this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      if (this.roundOffSettingVal == 2) {
        // Round after two decimal places
        this.totalNetAmt = Math.round(this.totalAmt * 100) / 100; // Round to two decimal places
        this.roundOffAmt =
          Math.round((this.totalNetAmt - this.totalAmt) * 100) / 100; // Calculate round-off amount
      } else if (this.roundOffSettingVal == 1) {
        // Round after one decimal place
        this.totalNetAmt = Math.round(this.totalAmt * 10) / 10; // Round to one decimal place
        this.roundOffAmt =
          Math.round((this.totalNetAmt - this.totalAmt) * 10) / 10; // Calculate round-off amount
      } else {
        // General round-off calculation
        if (this.roundOffAmt < 0.5) {
          this.totalNetAmt = this.totalAmt - this.roundOffAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.roundOffAmt = -this.roundOffAmt;
          this.sign = "fa-minus";
        } else {
          this.roundOffAmt = 1 - this.roundOffAmt;
          this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
          this.totalNetAmt = this.totalAmt + this.roundOffAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.sign = "fa-plus";
        }
      }
      this.form.get("total_amount")?.setValue(this.amount + vatAmount);
      this.form.get("net_amount")?.setValue(this.totalNetAmt);
      this.form.get("roundoff_amt")?.setValue(this.roundOffAmt);
      this.form.get("gross_amount")?.setValue(this.totalAmt);
      this.form.get("total_tax")?.setValue(this.totalTax);
      this.form.get("sgst_per")?.setValue(sgst_per);
      this.form.get("sgst_amt")?.setValue(sgst_amt);
      this.form.get("cgst_per")?.setValue(cgst_per);
      this.form.get("cgst_amt")?.setValue(cgst_amt);
      this.form.get("igst_per")?.setValue(igst_per);
      this.form.get("igst_amt")?.setValue(igst_amt);
      this.form.get("taxable_amt")?.setValue(taxable_amt + vatAmount);
      this.refreshPayment();
    }
  }

  //for deleting kot items
  onDeleteItem1(i: any) {
    this.KOT_data.splice(i, 1);
    this.calculateAmtWithTransTax();
  }

  //for deleting invoice items
  onDeleteItem(i: any) {
    this.itemsArr.splice(i, 1);
    this.calculateAmtWithTransTax();
  }

  //for select all kot's at view orders
  onSelectAllKOT(isChecked: boolean) {
    this.kotArr.forEach((kot: any) => {
      if (isChecked) {
        this.select_all = true;
        if (kot.added == false) {
          kot.added = true;
          this.soIdArr.push(kot.transaction_id);
          kot.items_details.forEach((item: any) => {
            var pos = this.itemsArr.findIndex(
              (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
            );
            if (pos == -1) {
              this.itemsArr.push(JSON.parse(JSON.stringify(item)));
            } else {
              this.itemsArr[pos].qty =
                parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);
              this.itemsArr[pos].amount =
                parseFloat(this.itemsArr[pos].qty) *
                parseFloat(this.itemsArr[pos].s_rate);
            }
          });
        }
      } else {
        this.select_all = false;
        if (kot.added == true) {
          kot.added = false;
          var kotIndex = this.soIdArr.findIndex(
            (e: any) => e === kot.transaction_id
          );
          this.soIdArr.splice(kotIndex, 1);
          kot.items_details.forEach((item: any) => {
            var pos = this.itemsArr.findIndex(
              (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
            );
            if (pos !== -1) {
              if (this.itemsArr[pos].qty == item.qty) {
                this.itemsArr.splice(pos, 1);
              } else {
                this.itemsArr[pos].qty =
                  parseFloat(this.itemsArr[pos].qty) - parseFloat(item.qty);
                this.itemsArr[pos].amount =
                  parseFloat(this.itemsArr[pos].qty) *
                  parseFloat(this.itemsArr[pos].s_rate);
              }
            }
          });
        }
      }
    });
    this.calculateAmtWithTransTax();
  }

  //for select indevidual kots
  onChangeKOT(kot: any, isChecked: boolean) {
    if (isChecked) {
      kot.added = true;
      this.soIdArr.push(kot.transaction_id);
      kot.items_details.forEach((item: any) => {
        var pos = this.itemsArr.findIndex(
          (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
        );
        if (pos == -1) {
          this.itemsArr.push(JSON.parse(JSON.stringify(item)));
        } else {
          this.itemsArr[pos].qty =
            parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);

          const a =
            parseFloat(this.itemsArr[pos].qty) *
            parseFloat(this.itemsArr[pos].s_rate);
          const b =
            parseFloat(this.itemsArr[pos].qty) * this.optionarray_amount;

          this.itemsArr[pos].amount = a + b;
        }
      });
    } else {
      kot.added = false;
      var kotIndex = this.soIdArr.findIndex(
        (e: any) => e === kot.transaction_id
      );
      this.soIdArr.splice(kotIndex, 1);
      kot.items_details.forEach((item: any) => {
        var pos = this.itemsArr.findIndex(
          (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
        );
        if (pos !== -1) {
          if (this.itemsArr[pos].qty == item.qty) {
            this.itemsArr.splice(pos, 1);
          } else {
            this.itemsArr[pos].qty =
              parseFloat(this.itemsArr[pos].qty) - parseFloat(item.qty);
            const a =
              parseFloat(this.itemsArr[pos].qty) *
              parseFloat(this.itemsArr[pos].s_rate);
            const b =
              parseFloat(this.itemsArr[pos].qty) * this.optionarray_amount;
            this.itemsArr[pos].amount = a + b;
          }
        }
      });
    }
    this.calculateAmtWithTransTax();
    this.select_all = this.checkSelectAll();
  }

  //for select all kots
  checkSelectAll() {
    var found = true;
    this.kotArr.forEach((kot: any) => {
      if (kot.added == false) {
        found = false;
      }
    });
    return found;
  }

  // main form for all transactions details
  form: FormGroup = new FormGroup({
    customer_name: new FormControl("Name"),
    email: new FormControl(null),
    address: new FormControl(null),
    trans_no: new FormControl("Auto Generated"),
    trans_date: new FormControl(new Date(), Validators.required),
    ledger_id: new FormControl(null),
    table_id: new FormControl(null),
    gstin: new FormControl(null),
    total_amount: new FormControl(0, Validators.required),
    total: new FormControl(0),
    total_tax: new FormControl(0),
    discount_amt: new FormControl(0),
    roundoff_amt: new FormControl(0),
    other1_label: new FormControl(null),
    other2_label: new FormControl(null),
    other1_amt: new FormControl(0),
    other2_amt: new FormControl(0),
    net_amount: new FormControl(0, Validators.required),
    cash: new FormControl(0, Validators.required),
    card: new FormControl(0, Validators.required),
    upi: new FormControl(0, Validators.required),
    card_ref_no: new FormControl(null),
    advance: new FormControl(0),
    balance: new FormControl(0),
    contact_no: new FormControl(null),
    taxslab: new FormControl(0),
    vattaxslab: new FormControl(0),
    dis_per: new FormControl(0),
    taxable_amt: new FormControl(0),
    sgst_per: new FormControl(0),
    sgst_amt: new FormControl(0),
    cgst_per: new FormControl(0),
    cgst_amt: new FormControl(0),
    igst_per: new FormControl(0),
    igst_amt: new FormControl(0),
    vat_per: new FormControl(0),
    vat_amt: new FormControl(0),
    gross_amount: new FormControl(0),
    item_qty: new FormControl(null),
    item_rate: new FormControl(null),
    item_amt: new FormControl(null),
    cash_receive: new FormControl(0),
    upi_receive: new FormControl(0),
    instruction: new FormControl(""),
    service_charge: new FormControl(0),
    service_charge_per: new FormControl(0),
  });

  // Initialise or Reset Form Group
  initializeFormGroup() {
    this.form.setValue({
      customer_name: "Name",
      email: null,
      address: null,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      table_id: null,
      gstin: null,
      total_amount: 0,
      discount_amt: 0,
      roundoff_amt: 0,
      other1_label: null,
      other2_label: null,
      other1_amt: 0,
      other2_amt: 0,
      net_amount: 0,
      total: 0,
      total_tax: 0,
      cash: 0,
      card: 0,
      upi: 0,
      card_ref_no: null,
      advance: 0,
      balance: 0,
      contact_no: null,
      dis_per: 0,
      taxable_amt: 0,
      sgst_per: 0,
      sgst_amt: 0,
      cgst_per: 0,
      cgst_amt: 0,
      igst_per: 0,
      igst_amt: 0,
      vat_per: 0,
      vat_amt: 0,
      gross_amount: 0,
      taxslab: 0,
      vattaxslab: 0,
      item_qty: null,
      item_rate: null,
      item_amt: null,
      cash_receive: 0,
      upi_receive: 0,
      instruction: null,
      service_charge: 0,
      service_charge_per: 0,
    });
    this.itemsArr = [];
    this.calculateAmtWithTransTax();
    this.soIdArr = [];
    this.kotArr
      .filter((x: any) => x.added === true)
      .forEach((x: any) => this.kotArr.splice(this.kotArr.indexOf(x), 1));
    this.allow_login = false;
    window.scroll(0, 0);
  }

  // on add other price.
  onOtherPrice() {
    if (this.form?.value?.total_amount) {
      this.calculateAmtWithTransTax();
    }
  }

  // on change cash card or upi
  onCashOrCard() {
    var total_advance =
      this.form?.value.cash + this.form.value.card + this.form.value.upi;
    var balance_amt = this.form.value.net_amount - total_advance;
    this.form.get("advance")?.setValue(total_advance);
    this.form.get("balance")?.setValue(balance_amt);
    if (this.form?.value.cash_receive >= this.form?.value.cash) {
      this.refund_amt = this.form.value.cash_receive - this.form.value.cash;
    }
  }

  // on change cash card or upi
  refreshPayment() {
    if (this.form?.value.cash_receive >= this.form?.value.net_amount) {
      this.form.get("cash")?.setValue(this.form.value.net_amount);
    }
    this.onCashOrCard();
  }

  // on change cash card or upi
  onCashReceive() {
    if (this.form?.value.cash_receive >= this.form?.value.net_amount) {
      this.form.get("cash")?.setValue(this.form.value.net_amount);
      this.onCashOrCard();
    }
  }

  // on change tax
  onTaxChange(e: any) {
    this.calculateAmtWithTransTax();
  }

  //on focus at item search bar
  onFocus(e: any) {
    const filter = "restaurant_menu_items";
    this.apiService.getCustomItemList(filter).subscribe(
      (result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      },
      (result: any) => {}
    );
  }

  //on change item qty on search item pannel
  onChangeItemQty(e: any, i: any) {
    let number: number = 0;
    let optionAmount: number = 0;
    if (this.KOT_data && this.KOT_data[i]) {
      let positiveQty = parseFloat(e.target.value) || 0;

      this.KOT_data[i].qty = positiveQty;
      if (this.KOT_data[i].optionAmount) {
        optionAmount = parseFloat(this.KOT_data[i].optionAmount);
      } else {
        optionAmount = number;
      }

      // let b: any = this.KOT_data[i].qty * this.optionarray_amount;
      // let a: any = this.KOT_data[i].qty * this.KOT_data[i].s_rate;

      // this.KOT_data[i].amount = parseFloat(a + b);
      this.KOT_data[i].amount =
        (parseFloat(this.KOT_data[i].s_rate) + optionAmount) *
        parseFloat(this.KOT_data[i].qty);
      // Call the calculation methods
      this.calculateAmtWithTax();
      this.calculateAmtWithTransTax();
    } else {
      console.error(
        `Item at index ${i} is undefined or itemsArr is not initialized.`
      );
    }
  }

  onChangeItemQtyy(e: any, i: any) {
    let number: number = 0;
    let optionAmount: number = 0;
    if (this.itemsArr && this.itemsArr[i]) {
      let positiveQty = parseFloat(e.target.value) || 0;

      this.itemsArr[i].qty = positiveQty;
      if (this.itemsArr[i].optionAmount) {
        optionAmount = parseFloat(this.itemsArr[i].optionAmount);
      } else {
        optionAmount = number;
      }

      // let b: any = this.itemsArr[i].qty * this.optionarray_amount;
      // let a: any = this.itemsArr[i].qty * this.itemsArr[i].s_rate;

      // this.itemsArr[i].amount = parseFloat(a + b);
      this.itemsArr[i].amount =
        (parseFloat(this.itemsArr[i].s_rate) + optionAmount) *
        parseFloat(this.itemsArr[i].qty);
      // Call the calculation methods
      this.calculateAmtWithTax();
      this.calculateAmtWithTransTax();
    } else {
      console.error(
        `Item at index ${i} is undefined or itemsArr is not initialized.`
      );
    }
  }

  // kot item quentity increase
  increase_qty(e: any, i: any) {
    if (this.KOT_data && this.KOT_data[i]) {
      let number: number = 0;
      let optionAmount: number = 0;
      let Quantity = parseFloat(e.qty);
      if (Quantity === 0.1) {
        this.KOT_data[i].qty = Quantity + 0.9;
      } else if (Quantity >= 1) {
        this.KOT_data[i].qty = Quantity + 1;
      } else {
        this.KOT_data[i].qty = Quantity + 1;
      }
      if (this.KOT_data[i].optionAmount) {
        optionAmount = parseFloat(this.KOT_data[i].optionAmount);
      } else {
        optionAmount = number;
      }

      this.KOT_data[i].amount =
        (parseFloat(this.KOT_data[i].s_rate) + optionAmount) *
        parseFloat(this.KOT_data[i].qty);

      this.calculateAmtWithTax();
      this.calculateAmtWithTransTax();
    }
  }

  // kot item quentity decrease
  decrease_qty(e: any, i: any) {
    if (this.KOT_data && this.KOT_data[i]) {
      let number: number = 0;
      let optionAmount: number = 0;
      if (parseFloat(e.qty) <= 0.1) {
        this.KOT_data[i].qty = 0.1;
      } else if (e.qty === 1 || parseFloat(e.qty) === 1.0) {
        this.KOT_data[i].qty = (e.qty - 0.9).toFixed(1);
      } else if (parseFloat(e.qty) < 1) {
        // Check if currentQty is close to 0.9 or 1 and set it to 0.1 if true
        this.KOT_data[i].qty = parseFloat(e.qty) - 1;
        if (
          parseFloat(this.KOT_data[i].qty) <= 0.0 ||
          this.KOT_data[i].qty <= 0
        ) {
          this.KOT_data[i].qty = 0.1;
        }
      } else {
        this.KOT_data[i].qty = (e.qty - 1).toFixed(1);
      }

      if (this.KOT_data[i].optionAmount) {
        optionAmount = parseFloat(this.KOT_data[i].optionAmount);
      } else {
        optionAmount = number;
      }
      this.KOT_data[i].amount =
        (parseFloat(this.KOT_data[i].s_rate )+ optionAmount) *
        parseFloat(this.KOT_data[i].qty);
      this.calculateAmtWithTax();
      this.calculateAmtWithTransTax();
    }
  }

  // inv item quentity increase
  increase_qty1(e: any, i: any) {
    // this.itemsArr[i].qty = e.qty + 1;
    // const b = this.itemsArr[i].qty * this.optionarray_amount;
    // const a = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    // this.itemsArr[i].amount = a + b;
    // this.calculateAmtWithTax();
    // this.calculateAmtWithTransTax();
    let number: number = 0;
    let optionAmount: number = 0;
    let Quantity = parseFloat(e.qty);
    if (Quantity === 0.1) {
      this.itemsArr[i].qty = Quantity + 0.9;
    } else if (Quantity >= 1) {
      this.itemsArr[i].qty = Quantity + 1;
    } else {
      this.itemsArr[i].qty = Quantity + 1;
    }
    if (this.itemsArr[i].optionAmount) {
      optionAmount = parseFloat(this.itemsArr[i].optionAmount);
    } else {
      optionAmount = number;
    }
    this.itemsArr[i].amount =
      (optionAmount + parseFloat(this.itemsArr[i].s_rate)) *
      parseFloat(this.itemsArr[i].qty);
    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
  }

  // inv  item quentity decrease
  decrease_qty1(e: any, i: any) {
    // if (e.qty >= 2) {
    //   this.itemsArr[i].qty = e.qty - 1;
    //   const b = this.itemsArr[i].qty * this.optionarray_amount;
    //   const a = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    //   this.itemsArr[i].amount = a + b;
    //   this.calculateAmtWithTax();
    //   this.calculateAmtWithTransTax();
    // }
    let number: number = 0;
    let optionAmount: number = 0;
    if (parseFloat(e.qty) <= 0.1) {
      this.itemsArr[i].qty = 0.1;
    } else if (e.qty === 1 || parseFloat(e.qty) === 1.0) {
      this.itemsArr[i].qty = (e.qty - 0.9).toFixed(1);
    } else if (parseFloat(e.qty) < 1) {
      // Check if currentQty is close to 0.9 or 1 and set it to 0.1 if true
      this.itemsArr[i].qty = parseFloat(e.qty) - 1;
      if (
        parseFloat(this.itemsArr[i].qty) <= 0.0 ||
        this.itemsArr[i].qty <= 0
      ) {
        this.itemsArr[i].qty = 0.1;
      }
    } else {
      this.itemsArr[i].qty = (e.qty - 1).toFixed(1);
    }
    if (this.itemsArr[i].optionAmount) {
      optionAmount = parseFloat(this.itemsArr[i].optionAmount);
    } else {
      optionAmount = number;
    }
    this.itemsArr[i].amount =
      (optionAmount + this.itemsArr[i].s_rate) *
      parseFloat(this.itemsArr[i].qty);
    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
  }

  //on item rate change on search item pannel
  onChangeItemRate(e: any, i: any) {
    this.itemsArr[i].s_rate = e.target.value;
    this.itemsArr[i].amount = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    this.calculateAmtWithTransTax();
  }

  // to download pdf
  downloadClick(dataObj: any, transNo: any) {
    this.apiService.downloadPdf(dataObj).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + transNo + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
        if (this.order_tab === "Take Away") {
          this.onKotAdd.emit(true);
          this.SaveAndPrint();
        }
        if (this.order_tab === "Dine-In") {
          this._generating_sales_order = false;
          this.panel3.open();
        }
      },
      (result: any) => {}
    );
  }

  // add kot from kot pannel
  onAddKOT() {
    const contact_no = this.form.value.contact_no;
    const email_id = this.form.value.email;
    const customer_name = this.form.value.customer_name;

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let isValid = true; // Flag to track form validity

    // Validate contact number length (assuming numeric input)
    if (contact_no && (!/^\d+$/.test(contact_no) || contact_no && contact_no.length < 10)) {
      notyf.error("Contact Number must be at least 10 digits long");
      isValid = false;
    }
    if (email_id && !emailRegex.test(email_id)) {
      notyf.error("Please Provide a Valid Email Address");
      isValid = false;
    }
    if (!customer_name) {
      notyf.error("Customer Name is required");
      isValid = false;
    }

    if (!isValid) {
      return; // Stop further execution if validation fails
    }

    let validItems: any[] = []
    let validItemsKot: any[] = []

    // Ensure itemsArr exists and all items have valid amounts
    if (this.order_tab === "Take Away") {
    validItems = this.itemsArr.filter((item: any) => item.amount > 0);
    }else{
      validItemsKot = this.KOT_data.filter((item: any) => item.amount > 0);
    }

    if (this.order_tab === "Take Away") {
      if (this.form.value.total_amount !== 0) {
        this._generating_sales_order = true;

        this.waitforTakeaway = true;
        this.model_kot.net_amount = 0;
        validItems.forEach((itm: any) => {
          this.model_kot.net_amount =
            parseFloat(this.model_kot.net_amount) + parseFloat(itm.amount);
        });
        if (this.itemsArr) {
          this._generating_sales_order = true;
          this.model_kot.items_details = validItems;
          this.model_kot.table_id = this.table_id;
          this.model_kot.ledger_id = 0;
          this.model_kot.orderMode = "Take Away";
          this.model_kot.instruction = this.form.get("instruction")!.value
            ? this.form.get("instruction")!.value
            : null;
          this.notesValueForEdit = this.form.get("instruction")!.value
            ? this.form.get("instruction")?.value
            : null;
          this.apiService.addSalesOrder(this.model_kot).subscribe(
            (result: any) => {
              if (result.success) {
                this.soIdArr.push(result.transaction_id);
                this.model_kot.trans_no = result.max_trans_value;
                this.model_kot.transaction_id = result.transaction_id;
                this.model_kot.trans_type = "SalesOrder";
                var tr_no = this.model_kot.trans_no;
                this.downloadClick(this.model_kot, tr_no);
                this.model_kot = { trans_date: new Date(), net_amount: 0 };
                this.kot_Dta.push(validItems);
                notyf.success("New KOT added successfully");
                // this.waitforTakeaway = false;
                this.form.get("instruction")?.reset();
              } else {
                notyf.error(result.message);
                this.waitforTakeaway = false;
              }
            },
            (result: any) => {
              this._generating_sales_order = false;
              notyf.error("Unable to add new KOT");
              this.waitforTakeaway = false;
            }
          );
        } else {
          notyf.error("Add atleast one item to continue");
          this._generating_sales_order = false;
          this.waitforkot = false;
        }
      } else {
        notyf.error("Please add items");
        this.waitforkot = false;
      }
    } else {
      this.waitforkot = true;
      // Check if any item in KOT_data has qty 0
      let hasInvalidQuantity = false;
      this.model_kot.net_amount = 0;
      validItemsKot.forEach((itm: any) => {
        if (itm.qty > 0) {
          this.model_kot.net_amount =
            parseFloat(this.model_kot.net_amount) +
            parseFloat(itm.amount) +
            (parseFloat(itm.sgst_amt) || 0) +
            (parseFloat(itm.cgst_amt) || 0) +
            (parseFloat(itm.igst_amt) || 0) +
            (parseFloat(itm.vat_amt) || 0);
        } else {
          hasInvalidQuantity = true;
          notyf.error("Input Quantity");
        }
      });
      // If any item had an invalid quantity, stop further execution
      if (hasInvalidQuantity) {
        this.waitforkot = false;
        return;
      }    

      if (validItemsKot?.length !== 0 && validItemsKot[0]?.qty > 0) {
        this.model_kot.items_details = validItemsKot;
        this.model_kot.table_id = this.table_id;
        this.model_kot.orderMode = "Dine-In";
        this.model_kot.ledger_id = 0;
        this.model_kot.instruction = this.form.get("instruction")!.value
          ? this.form.get("instruction")!.value
          : null;
        this.apiService.addSalesOrder(this.model_kot).subscribe(
          (result: any) => {
            if (result.success) {
              this.model_kot.trans_no = result.max_trans_value;
              this.model_kot.transaction_id = result.transaction_id;
              this.model_kot.trans_type = "SalesOrder";
              var tr_no = this.model_kot.trans_no;
              this.downloadClick(this.model_kot, tr_no);
              this.model_kot = { trans_date: new Date(), net_amount: 0 };
              this.KOT_data = [];
              validItemsKot = [];
              this.onKotAdd.emit(true);
              notyf.success("New KOT added successfully");
              this.waitforkot = false;
              this.form.get("instruction")!.reset();
            } else {
              notyf.error(result.message);
              this.waitforkot = false;
            }
          },
          (result: any) => {
            notyf.error("Unable to add new KOT");
            this.waitforkot = false;
          }
        );
      } else {
        notyf.error("Add atleast one item to continue");
        this.waitforkot = false;
      }
    }
    this.refreshComponent();
  }

  //on edit kot from all orders pannels
  onEditKot(kot_id: any) {
    var dataObj = {
      isAutoFocus: this.isAutoFocus,
      taxslab: this.form.value.taxslab,
      table_id: this.table_id,
      table_name: this.table_name,
      allowChangeRate: this.allowChangeRate,
      kot_id: kot_id,
    };

    this.dialog
      .open(RstKotComponent, {
        width: "900px",
        data: dataObj,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == "Edited") {
          this.updateKotList(kot_id); // Update the KOT list with the edited KOT
        }
      });
  }

  // update kot list
  updateKotList(kot_id: any) {
    // Remove the old KOT from the list
    const index = this.kotArr.findIndex(
      (kot: any) => kot.transaction_id === kot_id
    );
    if (index !== -1) {
      this.kotArr.splice(index, 1);
    }
  }

  convertFloat(val: any) {
    return parseFloat(val);
  }

  // all multilogin using toggle button for customer website
  onAllowLogin(allowLogin: boolean) {
    this.allow_login = allowLogin; // Update the local state
    this.apiService
      .updateSessionAllowLogin(this.allow_login, this.table_id)
      .subscribe(
        (result: any) => {
          if (result.success) {
            notyf.success("data updated successfully");
          } else {
            notyf.error(result.message);
          }
        },
        (result: any) => {}
      );
  }

  // on category clicked
  showitems(data: any) {

    // initializing the array
    this.items_data = [];
    this.itemArr = [];
    this.sub_category_data = [];

    this.selectedCategoryName = data.category_name;
    if (data.hassubcategory) {
      const cachedSubCategory = this.cacheService.get(
        `subCat-${data.category_id}`
      );

      if (cachedSubCategory) {
        this.sub_category_data = cachedSubCategory;
      } else {
        this.apiService.menuSubCategoryList(data.category_id).subscribe(
          (result: any) => {
            this.sub_category_data = result;
            this.cacheService.set(`subCat-${data.category_id}`, result);
          },
          (result: any) => {
            notyf.error(result.message);
          }
        );
      }
    } else {
      const cachedItems = this.cacheService.get(
        `cat-${data.category_id}-${this.hasPriceList}`
      );

      if (cachedItems) {
        this.items_data = cachedItems;
        this.itemArr = cachedItems;
      } else {
        this.apiService
          .menuItemListByCategory(data.category_id, this.table_id)
          .subscribe(
            (result: any) => {
              this.items_data = result;
              this.itemArr = result;
              this.cacheService.set(
                `cat-${data.category_id}-${this.hasPriceList}`,
                result
              );
            },
            (result: any) => {
              notyf.error(result.message);
            }
          );
      }
    }
  }

  // on sub category clicked
  showSubitems(sub_category_id: any) {
    const cachedItems = this.cacheService.get(
      `cat-${sub_category_id}-${this.table_id}`
    );

    if (cachedItems) {
      this.items_data = cachedItems;
      this.itemArr = cachedItems;
    } else {
      this.apiService
        .menuItemListBySubCategory(sub_category_id, this.table_id)
        .subscribe(
          (result: any) => {
            this.items_data = result;
            this.itemArr = result;
            this.cacheService.set(
              `cat-${sub_category_id}-${this.table_id}`,
              result
            );
          },
          (result: any) => {
            notyf.error(result.message);
          }
        );
    }
  }

  // on add instruction from take away
  instructiondataTakeaway(item: any, n: any) {
    this.itemArr = this.itemArr.filter((data: any) => {
      if (item.item_id == data.item_id) {
        data.instruction = n.value;
      }
      return data;
    });
  }

  // reset all data
  resetData() {
    this.itemsArr = [];
    this.soIdArr = [];
    this.kotArr = [];
    this.KOT_data = [];
    this.calculateAmtWithTransTax();
  }

  // on tab change from right pannel
  onChange(event: any) {
    const switchingTo = event.tab.textLabel;

    if (switchingTo === "Take Away") {
      if (this.kotArr && this.kotArr.length > 0) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: "400px",
          data: {
            message:
              "There is an existing KOT in Dine-In. Please create an invoice before switching to Take Away.",
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === true) {
            this.SaveAndPrint(); // Trigger the invoice creation
            this.resetData();
            this.order_tab = "Take Away";
            notyf.success("Switched to Take Away");
          } else {
            // Set a slight delay to allow the tab to switch back smoothly
            setTimeout(() => {
              this.order_tab = "Dine-In"; // Automatically switch back to Dine-In
              if (this.tabGroup) {
                this.tabGroup.selectedIndex = 0; // Assuming Dine-In is the first tab
              }
              notyf.error("Switched back to Dine-In");
            }, 100);
          }
        });
      } else {
        this.order_tab = "Take Away";
        this.resetData();
        notyf.success("Switched to Take Away");
      }
    } else if (switchingTo === "Dine-In") {
      this.order_tab = "Dine-In";
      notyf.success("Switched to Dine-In");
    }
  }

  // Method to handle KOT creation and invoice enforcement
  addItems(item: any) {
    if (this.order_tab === "Take Away") {
      this.addItemToTakeAway(item);
    } else if (this.order_tab === "Dine-In") {
      this.addItemToDineIn(item);
    }

    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
    this.itemControl.reset();
  }

  // add items to take away
  addItemToTakeAway(item: any) {
    const existingItemIndex = this.itemsArr.findIndex((search_item: any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true

      // Check base item details
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check childOptions if they exist
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
                childOpt.title
            );
          }
        );
      }

      // Check variant groups if they exist
      if (
        item.variant_groups &&
        item.variant_groups.length > 0 &&
        item.variant_groups[0].variants
      ) {
        isVariantsMatch = item.variant_groups[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.variant_groups &&
              search_item.variant_groups.length > 0 &&
              search_item.variant_groups[0].variants &&
              search_item.variant_groups[0].variants[index] &&
              search_item.variant_groups[0].variants[index].title ===
                variant.title
            );
          }
        );
      }

      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });
    let updatedTitle = this.updateItemTitle(item);

    if (existingItemIndex === -1) {
      item.qty = item.qty || 1;
      item.amount = this.calculateItemAmount(item);
      // item.item_code = updatedTitle;

      this.itemsArr.push({ ...item });
    } else {
      this.itemsArr[existingItemIndex].qty += item.qty || 1;
      this.itemsArr[existingItemIndex].amount = this.calculateItemAmount(
        this.itemsArr[existingItemIndex]
      );
    }
    this.resetItemInput();
    this.sitm.nativeElement.focus();
  }

  // add items to dine in
  // addItemToDineIn(item: any) {
  //   let kotData = JSON.parse(JSON.stringify(this.KOT_data)); // Copy KOT data
  //   let existingItemIndex = null; // To store the index of the matching item

  //   // Check if there's an item with the same variant and add-ons (or no add-ons or no variant)
  //   existingItemIndex = kotData.findIndex((existingItem: any) => {
  //     // Check if variant matches
  //     let variantMatch = existingItem.checkedVariant[0].variants.some((existVariant: any) => {
  //       return item.checkedVariant[0].variants.some((newVariant: any) => {
  //         return existVariant.id === newVariant.id; // Variant matches
  //       });
  //     });

  //     // Check if add-ons match
  //     let addonsMatch = existingItem.optionsData.some((existAddon: any) => {
  //       return existAddon.childOptions.some((existOption: any) => {
  //         return item.optionsData.some((newAddon: any) => {
  //           return newAddon.childOptions.some((newOption: any) => {
  //             return existOption.id === newOption.id; // Add-ons match
  //           });
  //         });
  //       });
  //     });
  //     let isExistingItem = false
  //     const hasExistingAddons = existingItem.optionsData && existingItem.optionsData.length > 0 ? (addonsMatch !== -1 ? true : false) : false;
  //     const hasNewAddons = item.optionsData && item.optionsData.length > 0 ? true : false;
  //     const hasExistingVariant = existingItem.checkedVariant && existingItem.checkedVariant.length > 0 ? (variantMatch !== -1 ? true : false) : false;
  //     const hasNewVariant = item.checkedVariant && item.checkedVariant.length > 0 ? true : false;

  //     // isExistingItem =  hasNewVariant ? hasExistingVariant ? hasNewAddons  : false
  //     if (hasNewVariant && hasExistingVariant) {
  //       if (hasNewAddons && hasExistingAddons) {
  //         isExistingItem = true
  //       } else if (!hasNewAddons) {
  //         isExistingItem = true
  //       } else {
  //         isExistingItem = false
  //       }
  //     } else if (!hasNewVariant) {
  //       if (hasNewAddons && hasExistingAddons) {
  //         isExistingItem = true
  //       } else {
  //         isExistingItem = false
  //       }
  //     } else {
  //       isExistingItem = false
  //     }
  //     // Handle no add-ons for matching (same variant, no add-ons)

  //     // Return true if variants and add-ons match, or there's no variant/add-ons match
  //     return isExistingItem
  //   });

  //   console.log("Existing Item Index:", existingItemIndex);

  //   if (existingItemIndex !== -1) {
  //     // If a matching item is found, increment its quantity
  //     kotData[existingItemIndex].qty += item.qty || 1;
  //     kotData[existingItemIndex].amount = this.calculateItemAmount(kotData[existingItemIndex]);
  //   } else {
  //     // If no matching item is found, add a new one
  //     item.qty = item.qty || 1;
  //     item.amount = this.calculateItemAmount(item); // Calculate initial amount
  //     kotData.push(item);
  //   }

  //   // Update the KOT data
  //   this.KOT_data = JSON.parse(JSON.stringify(kotData));

  //   // Open the panel if it's not expanded
  //   if (!this.panel.expanded) {
  //     this.panel.open();
  //   }
  // }

  updateItemTitle(item: any) {
    let newTitle = item.item_code; // Start with the original item name

    // Check for any checked variants
    if (item.checkedVariant && item.checkedVariant.length > 0) {
      item.checkedVariant.forEach((variantGroup: any) => {
        if (
          variantGroup.ischecked ||
          variantGroup.checked ||
          variantGroup.wera_active
        ) {
          newTitle += ` - ${variantGroup.title}`; // Append the parent variant group title if checked

          variantGroup.variants.forEach((variant: any) => {
            if (
              variant.ischecked ||
              variant.checked ||
              variantGroup.wera_active
            ) {
              newTitle += ` - ${variant.title}`; // Append the variant title if checked
            }
          });
        }
      });
    }

    // Check for any checked options
    if (item.optionsData && item.optionsData.length > 0) {
      item.optionsData.forEach((option: any) => {
        if (option.ischecked || option.checked || option.wera_active) {
          newTitle += ` - ${option.title}`; // Append option title if checked
        }

        // Handle child options, if available and checked
        if (option.childOptions && option.childOptions.length > 0) {
          option.childOptions.forEach((childOption: any) => {
            if (
              childOption.ischecked ||
              childOption.checked ||
              childOption.wera_active
            ) {
              newTitle += ` - ${childOption.title}`; // Append child option title if checked
            }
          });
        }
      });
    }

    return newTitle;
  }

  // addItemToDineIn(item: any) {
  //   let updatedTitle = this.updateItemTitle(item);
  //   console.log("this.KOT_data---------item--------",this.KOT_data,item);

  //   const existingItemIndex = this.KOT_data.findIndex(
  //     (search_item: any) => search_item.item_id === item.item_id && search_item.item_code === updatedTitle
  //   );

  //   if (existingItemIndex === -1) {
  //     item.qty = item.qty || 1;
  //     item.amount = this.calculateItemAmount(item);
  //     this.KOT_data.push({ ...item, item_code: updatedTitle });
  //   } else {
  //     this.KOT_data[existingItemIndex].qty += item.qty || 1;
  //     this.KOT_data[existingItemIndex].amount = this.calculateItemAmount(
  //       this.KOT_data[existingItemIndex]
  //     );
  //   }

  //   if (!this.panel.expanded) {
  //     this.panel.open();
  //   }
  // }

  //   addItemToDineIn(item: any) {
  //     let kotData = JSON.parse(JSON.stringify(this.KOT_data)); // Copy KOT data

  //     let existingItemIndex = null; // Store the index of existing matching item

  //     // Check if there is an item with the same variant and add-ons (or no add-ons)
  //     existingItemIndex = kotData.findIndex((existingItem: any) => {
  //         // Check for the same variant
  //         let variantMatch = existingItem.checkedVariant[0].variants.some((existVariant: any) => {
  //             return item.checkedVariant[0].variants.some((newVariant: any) => {
  //                 return existVariant.id === newVariant.id; // Variant matches
  //             });
  //         });

  //         // Check for the same add-ons
  //         let addonsMatch = existingItem.optionsData.some((existAddon: any) => {
  //             return existAddon.childOptions.some((existOption: any) => {
  //                 return item.optionsData.some((newAddon: any) => {
  //                     return newAddon.childOptions.some((newOption: any) => {
  //                         return existOption.id === newOption.id; // Add-ons match
  //                     });
  //                 });
  //             });
  //         });

  //         // Handle case where no add-ons are selected (match if both have no add-ons)
  //         let noAddonsMatch = (existingItem.optionsData.length === 0 && item.optionsData.length === 0);

  //         // Return true if both variant and add-ons match, or both have no add-ons
  //         return variantMatch && (addonsMatch || noAddonsMatch);
  //     });

  //     console.log("Existing Item Index:", existingItemIndex);

  //     if (existingItemIndex !== -1) {
  //         // If a matching item is found, increment its quantity
  //         kotData[existingItemIndex].qty += item.qty || 1;
  //         kotData[existingItemIndex].amount = this.calculateItemAmount(kotData[existingItemIndex]);
  //     } else {
  //         // If no matching item is found, add a new one
  //         item.qty = item.qty || 1;
  //         item.amount = this.calculateItemAmount(item); // Calculate initial amount
  //         kotData.push(item);
  //     }

  //     // Update the KOT data
  //     this.KOT_data = JSON.parse(JSON.stringify(kotData));

  //     // Open the panel if it's not expanded
  //     if (!this.panel.expanded) {
  //         this.panel.open();
  //     }
  // }

  // calculate item amount

  addItemToDineIn(item: any) {
    let updatedTitle = this.updateItemTitle(item);

    const existingItemIndex = this.KOT_data.findIndex((search_item: any) => {
      let isChildOptionsMatch = true; // Default to true
      let isVariantsMatch = true; // Default to true

      // Check base item details
      const isBasicMatch =
        search_item.item_id === item.item_id &&
        search_item.optionAmount === item.optionAmount &&
        search_item.checkedVariantAmount === item.checkedVariantAmount;

      // Check childOptions if they exist
      if (
        item.optionsData &&
        item.optionsData.length > 0 &&
        item.optionsData[0].childOptions
      ) {
        isChildOptionsMatch = item.optionsData[0].childOptions.every(
          (childOpt: any, index: number) => {
            return (
              search_item.optionsData &&
              search_item.optionsData.length > 0 &&
              search_item.optionsData[0].childOptions &&
              search_item.optionsData[0].childOptions[index] &&
              search_item.optionsData[0].childOptions[index].title ===
                childOpt.title
            );
          }
        );
      }

      // Check variant groups if they exist
      if (
        item.variant_groups &&
        item.variant_groups.length > 0 &&
        item.variant_groups[0].variants
      ) {
        isVariantsMatch = item.variant_groups[0].variants.every(
          (variant: any, index: number) => {
            return (
              search_item.variant_groups &&
              search_item.variant_groups.length > 0 &&
              search_item.variant_groups[0].variants &&
              search_item.variant_groups[0].variants[index] &&
              search_item.variant_groups[0].variants[index].title ===
                variant.title
            );
          }
        );
      }

      // Return true if all conditions match
      return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
    });

    if (existingItemIndex === -1) {
      item.qty = item.qty || 1;
      item.amount = this.calculateItemAmount(item);
      this.KOT_data.push({ ...item });
    } else {
      this.KOT_data[existingItemIndex].qty += item.qty || 1;
      this.KOT_data[existingItemIndex].amount = this.calculateItemAmount(
        this.KOT_data[existingItemIndex]
      );
    }

    if (this.panel && !this.panel.expanded) {
      this.panel.open();
    }
  }

  calculateItemAmount(item: any) {
    const baseAmount = item.qty * item.s_rate;
    const optionsAmount = item.qty * this.optionarray_amount;
    return baseAmount + optionsAmount;
  }

  // add item instructions to dine in
  instrucctiondata(item: any, n: any) {
    this.KOT_data = this.KOT_data.filter((data: any) => {
      if (item.item_id == data.item_id) {
        data.instruction = n.value;
      }
      return data;
    });
  }

  //on tab change from right pannel  like take away and dine in
  onChange1(event: any) {
    this.order_tab = event.tab.textLabel;
    this.KOT_data = [];
    if (this.order_tab === "Take Away") {
      notyf.success("Switched to Take Away");
    } else {
      notyf.success("Switched to Dine-In");
    }
  }

  // on add item from items card
  // addItemCardClicked(i: any) {
  //   const data = { item: i, status: "add" };
  //   // const index = this.KOT_data.findIndex(
  //   //   (itm: any) => itm.item_id === i.item_id
  //   // );
  //   // if (index !== -1) {
  //   //   this.addItemsone(i);
  //   // } else {
  //   i.options.length > 0 || i.variant_groups.length > 0
  //     ? this.openCustomization(data)
  //     : this.addItemsone(i);
  //   // }
  // }
  addItemCardClicked(i: any) {
    const data = { item: i, status: "add" };
    const index = this.KOT_data.findIndex(
      (itm: any) => itm.item_id === i.item_id && itm.item_name == i.item_name
    );
    if (index !== -1) {
      (i.options && i.options.length > 0) || (i.variant_groups && i.variant_groups.length) > 0
        ? this.openCustomization(data)
        : this.addItemsone(i);
    } else {
      (i.options && i.options.length) > 0 || (i.variant_groups && i.variant_groups.length) > 0
        ? this.openCustomization(data)
        : this.addItemsone(i);
    }
  }

  // to open item customization modal
  openCustomization(e: any) {
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(
      ItemsDialogComponent,
      {
        data: e,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const item = result.item;
        if (result.status === "add") {
          this.optionarray_amount = item.optionAmount;
          this.options_array = item.optionsData;
          this.checkedVariantAmount = item.checkedVariantAmount;
          this.checkedVariant = item.checkedVariant;
          this.addItems(item);
        } else {
          this.updateOrder(item);
        }
      }
    });
  }

  // update order like if any addons or variant is changed or added
  updateOrder(item: any) {
    this.optionarray_amount = item.optionAmount;
    this.options_array = item.optionsData;
    this.checkedVariantAmount = item.checkedVariantAmount;
    this.checkedVariant = item.checkedVariant;
    item.amount =
      item.checkedVariantAmount > 0
        ? (item.checkedVariantAmount + item.optionAmount) *
          (item.qty > 0 ? item.qty : 1)
        : item.optionAmount > 0
        ? item.optionAmount + item.s_rate * item.qty
        : item.qty > 0
        ? item.s_rate * item.qty
        : item.s_rate;
    if (this.isTransactionGSTslab === false) {
      if (item.tax_mode === "GST") {
        if (this.isLocal) {
          item.sgst_per = item.taxslab / 2;
          item.sgst_amt = item.amount * (item.sgst_per / 100) || 0;
          item.sgst_amt = Math.round(item.sgst_amt * 100) / 100;
          item.cgst_per = item.taxslab / 2;
          item.cgst_amt = item.amount * (item.cgst_per / 100) || 0;
          item.cgst_amt = Math.round(item.cgst_amt * 100) / 100;
        } else {
          item.igst_per = item.taxslab;
          item.igst_amt = item.amount * (item.igst_per / 100) || 0;
          item.igst_amt = Math.round(item.igst_amt * 100) / 100;
        }

        item.total_tax =
          item.sgst_amt || 0 + item.cgst_amt || 0 + item.igst_amt || 0;
      } else {
        item.vat_per = item.taxslab;
        item.vat_amt = item.amount * (item.igst_per / 100) || 0;
        item.vat_amt = Math.round(item.igst_amt * 100) / 100;
        item.total_tax = item.vat_amt || 0;
      }
      item.total = item.amount + item.total_tax;
      item.instruction = this.form && this.form.value.instruction;
    }
    this.updateOrderItem(item);
  }

  // update order item
  updateOrderItem(item: any) {
    if (this.order_tab === "Take Away") {
      // const index = this.itemsArr.findIndex(
      //   (itm: any) => itm.item_id === item.item_id
      // );

      const index = this.itemsArr.findIndex((search_item: any) => {
        let isChildOptionsMatch = true; // Default to true
        let isVariantsMatch = true; // Default to true

        // Check base item details
        const isBasicMatch =
          search_item.item_id === item.item_id &&
          search_item.optionAmount === item.optionAmount &&
          search_item.checkedVariantAmount === item.checkedVariantAmount;

        // Check childOptions if they exist
        if (
          item.optionsData &&
          item.optionsData.length > 0 &&
          item.optionsData[0].childOptions
        ) {
          isChildOptionsMatch = item.optionsData[0].childOptions.every(
            (childOpt: any, index: number) => {
              return (
                search_item.optionsData &&
                search_item.optionsData.length > 0 &&
                search_item.optionsData[0].childOptions &&
                search_item.optionsData[0].childOptions[index] &&
                search_item.optionsData[0].childOptions[index].title ===
                  childOpt.title
              );
            }
          );
        }

        // Check variant groups if they exist
        if (
          item.checkedVariant &&
          item.checkedVariant.length > 0 &&
          item.checkedVariant[0].variants
        ) {
          isVariantsMatch = item.checkedVariant[0].variants.every(
            (variant: any, index: number) => {
              return (
                search_item.checkedVariant &&
                search_item.checkedVariant.length > 0 &&
                search_item.checkedVariant[0].variants &&
                search_item.checkedVariant[0].variants[index] &&
                search_item.checkedVariant[0].variants[index].title ===
                  variant.title
              );
            }
          );
        }

        // Return true if all conditions match
        return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
      });

      if (index !== -1) {
        this.itemsArr[index] = item;
      }
      this.calculateAmtWithTransTax();
    } else {
      // const index = this.KOT_data.findIndex(
      //   (itm: any) => itm.item_id === item.item_id
      // );

      const index = this.itemsArr.findIndex((search_item: any) => {
        let isChildOptionsMatch = true; // Default to true
        let isVariantsMatch = true; // Default to true

        // Check base item details
        const isBasicMatch =
          search_item.item_id === item.item_id &&
          search_item.optionAmount === item.optionAmount &&
          search_item.checkedVariantAmount === item.checkedVariantAmount;

        // Check childOptions if they exist
        if (
          item.optionsData &&
          item.optionsData.length > 0 &&
          item.optionsData[0].childOptions
        ) {
          isChildOptionsMatch = item.optionsData[0].childOptions.every(
            (childOpt: any, index: number) => {
              return (
                search_item.optionsData &&
                search_item.optionsData.length > 0 &&
                search_item.optionsData[0].childOptions &&
                search_item.optionsData[0].childOptions[index] &&
                search_item.optionsData[0].childOptions[index].title ===
                  childOpt.title
              );
            }
          );
        }

        // Check variant groups if they exist
        if (
          item.checkedVariant &&
          item.checkedVariant.length > 0 &&
          item.checkedVariant[0].variants
        ) {
          isVariantsMatch = item.checkedVariant[0].variants.every(
            (variant: any, index: number) => {
              return (
                search_item.checkedVariant &&
                search_item.checkedVariant.length > 0 &&
                search_item.checkedVariant[0].variants &&
                search_item.checkedVariant[0].variants[index] &&
                search_item.checkedVariant[0].variants[index].title ===
                  variant.title
              );
            }
          );
        }

        // Return true if all conditions match
        return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
      });

      if (index !== -1) {
        this.KOT_data[index] = item;
      }
    }
  }

  // open item customisation modal from search
  openCustomizationsearch(e: any, b: any) {
    this.optionarray_amount = 0;
    this.checkedVariantAmount = 0;
    this.options_array = [];
    this.checkedVariant = [];
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(
      ItemsDialogComponent,
      {
        data: e,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.optionarray_amount = result.optionAmount;
        this.options_array = result.optionsData;
        this.checkedVariant = result.checkedVariant;
        this.checkedVariantAmount = result.checkedVariantAmount;
        const isCustomised = true;
        this.populateItemData(result, isCustomised);
      }
    });
  }

  // celar table if there is customer web app active in the table in the table.
  clearTable() {
    this.webappActiveTable = false;
    this.apiService
      .updateActiveTable_inactive(this.webappActiveTable, this.table_id)
      .subscribe(
        (result: any) => {
          if (result.success) {
            notyf.success("Table Clear successfully");
          } else {
            notyf.error(result.message);
          }
        },
        (result: any) => {}
      );
  }

  // nevigate back
  backToTable() {
    this.router.navigate(["/rst-dashboard"]);
  }

  // to open receipt dialog
  receiptDialog(e: any) {
    const dialogRef: MatDialogRef<ReceiptDialogComponent> = this.dialog.open(
      ReceiptDialogComponent,
      {
        data: e.display_trans_no,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.load();
      }
    });

    // After the receipt is generated, refresh only this user's component
    this.refreshComponent();
  }

  // to open table transfer dialog
  openTransferDialog(): void {
    const dialogRef: MatDialogRef<TabletransferDialogComponent> =
      this.dialog.open(TabletransferDialogComponent, {
        width: "400px",
        data: this.table_id,
      });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "true") {
        this.initializeFormGroup();
        this.load();
      }
    });
  }

  // clear water and waiter if any customer called from customer web app
  clearwaterwaiter() {
    this.apiService.updatewaterwaiter(this.table_id).subscribe(
      (result: any) => {
        if (result) {
          notyf.success("Water/waiter Clear successfully");
          this.load();
        } else {
          notyf.error(result.message);
        }
      },
      (result: any) => {}
    );
  }

  // Updated event handler for Enter key press
  onEnterKey(event: any) {
    // Prevent default behavior and check if the key is Enter
    if (event.key === "Enter") {
      event.preventDefault();

      // If focus is on the search input (sitm.nativeElement)
      if (event.target === this.sitm.nativeElement) {
        // Check if there is a valid item in the filteredOptions list
        this.filteredOptions.subscribe((options) => {
          if (options && options.length === 1) {
            // Set the selected item and focus on the quantity field
            this.itemControl.setValue(options[0]);

            setTimeout(() => {
              if (this.qty1 && this.qty1.nativeElement) {
                this.qty1.nativeElement.focus();
              }
            }, 0);
          }
        });
      } else if (event.target === this.qty1.nativeElement) {
        // If Enter is pressed while in the quantity input, add the item
        this.onAddItem(); // This method will handle adding the item to KOT
      }
    }
  }

  // ======================disposal
  showdisposalitems(data: any) {
    if (Array.isArray(this.disposalItemData)) {
      this.disposalItemlist = this.disposalItemData.filter((e: any) => {
        return e.category_id === data;
      });
    } else {
      console.error('disposalItemData is not defined or not an array');
      // Optionally, you can initialize disposalItemData here if necessary
      this.disposalItemlist = [];
    }
  }

  // verify nc otp
  verifyEmailOtp(validationCode: string) {
    const requestBody = { code: validationCode };
    this.apiService.verifyemailotp(requestBody).subscribe(
      (result: any) => {
        if (result && result.success) {
          this.otpVerified = true;
          this.otpInvalid = false;
          this.formSubmitted = true;

          notyf.success("OTP verified successfully");
        }
        if (result && !result.success) {
          this.otpInvalid = true;
          this.otpVerified = false;
          notyf.error(result);
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }

  // on focus on discount field
  onDiscountFocus(isFocused: boolean) {
    this.isdiscountFocused = isFocused;
    if (!isFocused) {
      const discountControl: any = this.form.get("dis_per");
      if (discountControl.value === 0 || discountControl.value === null) {
        discountControl.setValue(null);
      }
    }
  }

  onServiceFocus(isFocused: boolean) {
    this.isdiscountFocused = isFocused;
    if (!isFocused) {
      const serviceChargeControl: any = this.form.get("service_charge_per");
      if (serviceChargeControl.value === 0 || serviceChargeControl.value === null) {
        serviceChargeControl.setValue(null);
      }
    }
  }

  // on discount per change
  onDiscountPer(event: any) {
    const totalAmount = this.form.get("total_amount")?.value || 0;
    const discountPer = this.form.get("dis_per")?.value || 0;
    const discountAmount = (totalAmount * discountPer) / 100;
    this.form.get("discount_amt")?.setValue(discountAmount);
    this.updateGrossAmount();
  }

  onServicePer(event: any) {
    const totalAmount = this.form.get("total_amount")?.value || 0;

    const serviceChargePer = parseFloat(event.target.value) || 0; // Get the input value
    this.form.get("service_charge_per")?.setValue(serviceChargePer);

    // const serviceChargePer = this.form.get("service_per")?.value || 0;
    const serviceChargeAmount = (totalAmount * serviceChargePer) / 100;
    this.form.get("service_charge")?.setValue(serviceChargeAmount);
    this.updateGrossAmount();
  }



  // update gross amount
  updateGrossAmount(): void {
    const totalAmount = this.form.get("total_amount")?.value || 0;
    const discountAmount = this.form.get("discount_amt")?.value || 0;
    const serviceChargeAmount = this.form.get("service_charge")?.value || 0;
    const taxAmount = this.form.get("total_tax")?.value || 0;
    const otherCharges =
      (this.form.get("other1_amt")?.value || 0) +
      (this.form.get("other2_amt")?.value || 0);
    const grossAmount = totalAmount - discountAmount + taxAmount + otherCharges + serviceChargeAmount;
    const formattedGrossAmount = parseFloat(grossAmount).toFixed(2);
    this.form.get("gross_amount")?.setValue(formattedGrossAmount);
    this.updateNetAmount();
  }

  // update net amount
  updateNetAmount(): void {
    const grossAmount = this.form.get("gross_amount")?.value || 0;
    const roundOff = this.form.get("roundoff_amt")?.value || 0;
    const netAmount = grossAmount + roundOff;
    this.form.get("net_amount")?.setValue(netAmount);
    this.calculateAmtWithTransTax();
  }

  // calculate amt with change on discount amount
  onDiscountAmt(event: any) {
    this.form.get("dis_per")!.setValue(0);
    this.calculateAmtWithTransTax();
  }

  onQtyFocus(e: any) {
    if (e) {
      this.isQtyFocused = true;
    } else {
      this.isQtyFocused = false;

      // Ensure the input value is not less than 0.1
      // const itemQtyControl: any = this.form.get("item_qty");
      // if (parseFloat(itemQtyControl.value) < 0.1) {
      //   itemQtyControl.setValue(0.1);  // Set back to 1.0 if invalid
      // } else {

      //   // itemQtyControl.setValue(parseFloat(itemQtyControl.value).toFixed(1));
      // }
    }
  }

  // Increment the quantity
  incrementQty() {
    
    // Get the current value from the form control, or default to 0 if it is empty/invalid
    const currentQty = parseFloat(this.form.get("item_qty")?.value) || 0;
    let newQty = 0;

    // Check if the current value is the minimum value (0.1), if so, increment by 0.9 to make it 1
    if (currentQty === 0.1) {
      newQty = currentQty + 0.9;
    } else {
      // For all other values, increment by 1
      newQty = currentQty + 1;
    }

    // Set the new quantity value in the form control and ensure it's to 1 decimal place
    this.form.get("item_qty")?.setValue(newQty.toFixed(1));

    // Trigger the recalculation of the item amount
    this.calculateItemAmt({ target: { value: newQty } });
  }

  // Decrement the quantity
  decrementQty() {
    let newQty = 0;
    let subtractValue = 1;
    const currentQty = parseFloat(this.form.get("item_qty")?.value) || 0; // Get the current value or default to 0
    if (currentQty <= 0.1) {
      // Prevent going below zero
      newQty = 0.1;
    } else if (currentQty === 1) {
      newQty = currentQty - 0.9;
    } else if (currentQty < 1) {
      // Check if currentQty is close to 0.9 or 1 and set it to 0.1 if true
      newQty = currentQty - subtractValue;
      if (newQty <= 0.0 || newQty <= 0) {
        newQty = 0.1;
      }
    } else {
      newQty = currentQty - 1; // Decrement by 1
    }
    this.form.get("item_qty")?.setValue(newQty.toFixed(1)); // Set the new value with one decimal place
    this.calculateItemAmt({ target: { value: newQty } }); // Trigger any recalculations
  }

  calculateItemAmt(event: any) {

    const sitmInputValue = this.itemControl?.value;

    if (!sitmInputValue) {
      // this.form.get("item_qty")!.setValue(0);
      return;
    }

    const s_rate = this.form.value.item_rate;
    const s_Rate = parseFloat(s_rate);
    
    let qty = this.form?.value.item_qty;
    if (typeof qty == "string") {
      qty = parseFloat(this.form?.value.item_qty);
    }

    if (parseFloat(qty) <= 0 || qty === "" || isNaN(qty)) {
      qty = 0.0;
    }

    // const optionAmount:any = this.optionarray_amount
    // const OptionAmount = parseFloat(optionAmount)
    // console.log("optionAmount----", optionAmount);
    
    let amount = (qty ? qty : 0 )* (s_Rate ? s_Rate : 0);
    
    this.form.get("item_amt")!.setValue(amount);
    this.model.qty = parseFloat(qty);
    this.model.s_rate = (s_rate != null && !isNaN(s_rate)) ? s_rate: null;
    this.model.amount = amount;
    this.form.value.item_qty = this.model.qty;
    if (event.key === "Enter" && !this.isQtyFocused) {
      this.onAddItem();
      this.sitm.nativeElement.focus();
    }

    if (event.key === "Enter" && this.isQtyFocused) {
      // this.s_rate1.nativeElement.focus();
    }

    const rate = this.form.get("item_rate")?.value || 0;
    // const amount = qty * rate;
    this.form.get("item_amt")?.setValue(amount);
  }

  // on add item central method
  addItemsone(item: any) {
    if (this.order_tab === "Take Away") {
      // Make sure KOT List is initialized
      if (!this.itemsArr) this.itemsArr = [];

      // Check if item.item_id exist in KOT list
      var result_items = this.itemsArr.filter((search_item: any) => {
        return search_item.item_id === item.item_id;
      });
      var found_item = null;
      var new_item_Added = false;
      if (result_items && result_items.length > 0) found_item = result_items[0];

      // If item not exist in KOT then add new item
      if (!found_item) {
        found_item = item;
        new_item_Added = true;

        found_item.qty = 1;
        found_item.amount = +item.s_rate;
        // this.itemsArr.push(item);
      } else {
        new_item_Added = false;
        // If Item already exist in KOT then increase qty and calcluate new amount
        found_item.qty++;
        found_item.amount = found_item.qty * parseFloat(item.s_rate);
      }

      if (new_item_Added) this.itemsArr.push(found_item);
    } else {
      // Make sure KOT List is initialized
      if (!this.KOT_data) this.KOT_data = [];

      // Check if item.item_id exist in KOT list
      var result_items = this.KOT_data.filter((search_item: any) => {
        return search_item.item_id === item.item_id;
      });
      var found_item = null;
      var new_item_Added = false;

      if (result_items && result_items.length > 0) found_item = result_items[0];

      // If item not exist in KOT then add new item
      if (!found_item) {
        found_item = item;
        new_item_Added = true;

        found_item.qty = 1;
        found_item.amount = +item.s_rate;
        found_item.instruction = "";
      } else {
        new_item_Added = false;
        // If Item already exist in KOT then increase qty and calcluate new amount
        found_item.qty++;
        found_item.amount = found_item.qty * parseFloat(item.s_rate);
      }

      if (new_item_Added) this.KOT_data.unshift(found_item);

      if (this.panel && !this.panel.expanded) {
        this.panel.open();
      }
    }

    // Refersh total amounts

    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
    // }
  }

  // update addons and variant to any items.
  updateCustomisation(item: any) {
    let updateItem: any;
    if (this.KOT_data && this.KOT_data.length > 0) {
      // updateItem = this.KOT_data.filter((e: any) => e.item_id == itm.item_id && e.item_code == itm.item_code);
      updateItem = this.KOT_data.filter((search_item: any) => {
        let isChildOptionsMatch = true; // Default to true
        let isVariantsMatch = true; // Default to true

        // Check base item details
        const isBasicMatch =
          search_item.item_id === item.item_id &&
          search_item.optionAmount === item.optionAmount &&
          search_item.checkedVariantAmount === item.checkedVariantAmount;

        // Check childOptions if they exist
        if (
          item.optionsData &&
          item.optionsData.length > 0 &&
          item.optionsData[0].childOptions
        ) {
          isChildOptionsMatch = item.optionsData[0].childOptions.every(
            (childOpt: any, index: number) => {
              return (
                search_item.optionsData &&
                search_item.optionsData.length > 0 &&
                search_item.optionsData[0].childOptions &&
                search_item.optionsData[0].childOptions[index] &&
                search_item.optionsData[0].childOptions[index].title ===
                  childOpt.title
              );
            }
          );
        }

        // Check variant groups if they exist
        if (
          item.checkedVariant &&
          item.checkedVariant.length > 0 &&
          item.checkedVariant[0].variants
        ) {
          isVariantsMatch = item.checkedVariant[0].variants.every(
            (variant: any, index: number) => {
              return (
                search_item.checkedVariant &&
                search_item.checkedVariant.length > 0 &&
                search_item.checkedVariant[0].variants &&
                search_item.checkedVariant[0].variants[index] &&
                search_item.checkedVariant[0].variants[index].title ===
                  variant.title
              );
            }
          );
        }

        // Return true if all conditions match
        return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
      });
    } else {
      updateItem = this.itemsArr.filter((search_item: any) => {
        let isChildOptionsMatch = true; // Default to true
        let isVariantsMatch = true; // Default to true

        // Check base item details
        const isBasicMatch =
          search_item.item_id === item.item_id &&
          search_item.optionAmount === item.optionAmount &&
          search_item.checkedVariantAmount === item.checkedVariantAmount;

        // Check childOptions if they exist
        if (
          item.optionsData &&
          item.optionsData.length > 0 &&
          item.optionsData[0].childOptions
        ) {
          isChildOptionsMatch = item.optionsData[0].childOptions.every(
            (childOpt: any, index: number) => {
              return (
                search_item.optionsData &&
                search_item.optionsData.length > 0 &&
                search_item.optionsData[0].childOptions &&
                search_item.optionsData[0].childOptions[index] &&
                search_item.optionsData[0].childOptions[index].title ===
                  childOpt.title
              );
            }
          );
        }

        // Check variant groups if they exist
        if (
          item.checkedVariant &&
          item.checkedVariant.length > 0 &&
          item.checkedVariant[0].variants
        ) {
          isVariantsMatch = item.checkedVariant[0].variants.every(
            (variant: any, index: number) => {
              return (
                search_item.checkedVariant &&
                search_item.checkedVariant.length > 0 &&
                search_item.checkedVariant[0].variants &&
                search_item.checkedVariant[0].variants[index] &&
                search_item.checkedVariant[0].variants[index].title ===
                  variant.title
              );
            }
          );
        }

        // Return true if all conditions match
        return isBasicMatch && isChildOptionsMatch && isVariantsMatch;
      });
    }

    const data = { item: updateItem[0], status: "update" };

    this.openCustomization(data);
  }

  // calculate tax for kot items
  calculateAmtWithTax() {
    if (this.isTransactionGSTslab === false) {
      for (var i = 0; i < this.KOT_data?.length; i++) {
        if (this.KOT_data[i].tax_mode === "GST") {
          if (this.isLocal) {
            this.KOT_data[i].sgst_per = this.KOT_data[i].taxslab / 2;
            this.KOT_data[i].sgst_amt =
              this.KOT_data[i].amount * (this.KOT_data[i].sgst_per / 100) || 0;
            this.KOT_data[i].sgst_amt =
              Math.round(this.KOT_data[i].sgst_amt * 100) / 100;
            this.KOT_data[i].cgst_per = this.KOT_data[i].taxslab / 2;
            this.KOT_data[i].cgst_amt =
              this.KOT_data[i].amount * (this.KOT_data[i].cgst_per / 100) || 0;
            this.KOT_data[i].cgst_amt =
              Math.round(this.KOT_data[i].cgst_amt * 100) / 100;
          } else {
            this.KOT_data[i].igst_per = this.KOT_data[i].taxslab;
            this.KOT_data[i].igst_amt =
              this.KOT_data[i].amount * (this.KOT_data[i].igst_per / 100) || 0;
            this.KOT_data[i].igst_amt =
              Math.round(this.KOT_data[i].igst_amt * 100) / 100;
          }

          this.KOT_data[i].total_tax =
            this.KOT_data[i].sgst_amt ||
            0 + this.KOT_data[i].cgst_amt ||
            0 + this.KOT_data[i].igst_amt ||
            0;
        } else {
          this.KOT_data[i].vat_per = this.KOT_data[i].taxslab;
          this.KOT_data[i].vat_amt =
            this.KOT_data[i].amount * (this.KOT_data[i].vat_per / 100) || 0;
          this.KOT_data[i].vat_amt =
            Math.round(this.KOT_data[i].vat_amt * 100) / 100;
          this.KOT_data[i].total_tax = this.KOT_data[i].vat_amt || 0;
        }
        this.KOT_data[i].total_tax =
          this.KOT_data[i].sgst_amt +
          this.KOT_data[i].cgst_amt +
          this.KOT_data[i].igst_amt +
          this.KOT_data[i].vat_amt;

        this.KOT_data[i].taxable_amt =
          this.amount - this.form.value.discount_amt;

        this.KOT_data[i].net_amount =
          this.KOT_data[i].taxable_amt + this.KOT_data[i].total_tax;
      }
    }
  }

  // get ebill
  // getEbillingdata() {
  //   this.apiService.getEBillAcountMapData().subscribe(
  //     (result) => {
  //       this.ebillData = result;

  //     },
  //     (err: any) => {
  //       notyf.error("unable to load e-bill data");
  //     }
  //   );
  // }

  onOnlyAddKOT() {
    if (this.order_tab === "Take Away") {
      if (this.form?.value.total_amount !== 0) {
        this.waitforTakeaway = true;
        this.model_kot.net_amount = 0;
        this.itemsArr.forEach((itm: any) => {
          this.model_kot.net_amount = this.model_kot.net_amount + itm.amount;
        });
        if (this.itemsArr) {
          this._generating_sales_order = true;
          this.model_kot.items_details = this.itemsArr;
          this.model_kot.table_id = this.table_id;
          this.model_kot.ledger_id = 0;
          this.model_kot.orderMode = "Take Away";

          this.model_kot.instruction = this.form.get("instruction")!.value
            ? this.form.get("instruction")!.value
            : null;
          this.notesValueForEdit = this.form.get("instruction")!.value
            ? this.form.get("instruction")!.value
            : null;
          this.apiService.addSalesOrder(this.model_kot).subscribe(
            (result: any) => {
              if (result) {
                this.soIdArr.push(result.transaction_id);
                this.model_kot.trans_no = result.max_trans_value;
                this.model_kot.transaction_id = result.transaction_id;
                this.model_kot.trans_type = "SalesOrder";
                var tr_no = this.model_kot.trans_no;
                this.model_kot = { trans_date: new Date(), net_amount: 0 };
                this.kot_Dta.push(this.itemsArr);
                notyf.success("New KOT added successfully");
                if (this.order_tab === "Dine-In") {
                  this.panel3.open();
                }
                this.waitforTakeaway = false;
                this.form.get("instruction")!.reset();
              } else {
                notyf.error(result.message);
                this.waitforTakeaway = false;
              }
            },
            (result: any) => {
              this._generating_sales_order = false;
              notyf.error("Unable to add new KOT");
              this.waitforTakeaway = false;
            }
          );
        } else {
          notyf.error("Add atleast one item to continue");
          this._generating_sales_order = false;
          this.waitforkot = false;
        }
      } else {
        notyf.error("Please add items");
        this.waitforkot = false;
      }
    } else {
      this.waitforkot = true;
      this.model_kot.net_amount = 0;

      // Check if any item in KOT_data has qty 0
      let hasInvalidQuantity = false;

      this.KOT_data.forEach((itm: any) => {
        if (itm.qty > 0) {
          // Calculate net amount for valid quantities
          this.model_kot.net_amount +=
            itm.amount +
            (itm.sgst_amt || 0) +
            (itm.cgst_amt || 0) +
            (itm.igst_amt || 0) +
            (itm.vat_amt || 0);
        } else {
          // Set flag to true if qty is 0 and show error
          hasInvalidQuantity = true;
          notyf.error("One of the quantities was not inputted");
        }
      });

      // If any item had an invalid quantity, stop further execution
      if (hasInvalidQuantity) {
        this.waitforkot = false;
        return;
      }

      if ((this.KOT_data && this.KOT_data.length )!== 0) {
        this.model_kot.items_details = this.KOT_data;
        this.model_kot.table_id = this.table_id;
        this.model_kot.orderMode = "Dine-In";
        this.model_kot.ledger_id = 0;
        this.model_kot.instruction = this.form.get("instruction")!.value
          ? this.form.get("instruction")!.value
          : null;

        this.apiService.addSalesOrder(this.model_kot).subscribe(
          (result: any) => {
            if (result) {
              this.model_kot.trans_no = result.max_trans_value;
              this.model_kot.transaction_id = result.transaction_id;
              this.model_kot.trans_type = "SalesOrder";
              var tr_no = this.model_kot.trans_no;

              this.model_kot = { trans_date: new Date(), net_amount: 0 };
              this.KOT_data = [];
              this.onKotAdd.emit(true);
              notyf.success("New KOT added successfully");
              this.waitforkot = false;

              this.form.get("instruction")!.reset();
              if (this.order_tab === "Dine-In") {
                this.panel3.open();
              }
            } else {
              notyf.error(result.message);
              this.waitforkot = false;
            }
          },
          (result: any) => {
            notyf.error("Unable to add new KOT");
            this.waitforkot = false;
          }
        );
      } else {
        notyf.error("Add at least one item to continue");
        this.waitforkot = false;
      }
    }
  }

  // crm save contect form form
  saveContact(): void {
    if (this.form.valid) {
      const contactData = {
        name: this.form?.value.customer_name,
        email_id: this.form?.value.email,
        contact_no: this.form?.value.contact_no,
        gstin: this.form?.value.gstin,
        shipping_address1: this.form?.value.address,
      };

      if (this.isEditMode && this.editContactId) {
        this.apiService
          .editContact({ ...contactData, id: this.editContactId })
          .subscribe(
            (response: any) => {
              if (response.success) {
                this.resetForm();
              }
            },
            (error: any) => {
              console.error("Error editing contact", error);
            }
          );
      } else {
        this.apiService.addContact(contactData).subscribe(
          (response: any) => {
            if (response.success) {
              this.resetForm();
            }
          },
          (error: any) => {
            console.error("Error adding contact", error);
          }
        );
      }
    }
  }

  resetForm(): void {
    this.form.patchValue({
      customer_name: "",
      email: "",
      gstin: "",
    });

    this.isEditMode = false;
    this.editContactId = null;
  }

  //on enter customer phone no invoice
  onInput(event: any): void {
    event.preventDefault();
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, "");

    // Limit the length to 10 digits
    if (value && value.length > 10) {
      value = value.slice(0, 10);

      this.errorMessage = "Number should not exceed 10 digits.";
    } else {
      this.errorMessage = null;
    }

    this.inputValue = value;
    inputElement.value = value; // Update the input field value

    this.searchByPhone(value);
  }

  // on select customer phone from list no invoice
  handleInputChange(value: string | null | undefined): void {
    if (!value || value.trim() === "") {
      this.listNumber = []; // Clear listNumber if input value is empty or null/undefined
    } else {
      this.apiService.searchNumber(value.trim()).subscribe(
        (response: any[]) => {
          this.listNumber = response || []; // Directly assign the response if it's an array, otherwise, clear the list
        },
        (error: any) => {
          console.error("API error:", error);
          this.listNumber = []; // Optionally, clear the list on error
        }
      );
    }
  }

  setInputValue(value: string): void {
    this.inputValue = value;
  }
  handleEnterKey(event: any): void {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior of Enter key
      this.searchByPhone((event.target as HTMLInputElement).value); // Trigger search by phone
    }
  }

  searchByPhone(phone: string): void {
    this.inputValue = phone;
    this.apiService.fetchContactByPhone(phone).subscribe(
      (response: any) => {
        if (response.success && response.contact) {
          this.form.patchValue({
            ...this.form.value,
            customer_name: response.contact.name,
            email: response.contact.email_id,
            address: response.contact.shipping_address1,
          });
          // }
          this.isEditMode = true;
          this.editContactId = response.contact.id;
        } else {
          this.resetForm();
          this.isEditMode = false;
          this.editContactId = null;
        }
      },
      (error: any) => {
        console.error("Error searching contact by phone", error);
      }
    );
  }

  private Savedata(action: string) {
    this._generating_sales_order = false;
    this.waitforinvoice = true;
    let orderMode = this.order_tab === "Take Away" ? "Take Out" : "Dine In";
    

    let validItems: any[] = []

    // Ensure itemsArr exists and all items have valid amounts
    if (this.itemsArr) {
    validItems = this.itemsArr.filter((item: any) => item.amount > 0);
    }

    // Conditionally modify eBillData
    let eBillData;
    if (action === "print") {
      // For printing action
      eBillData = this.ebillData.map((item: any) => ({
        ...item,
        checkbox_value: false,
      }));
    } else {
      // For eBilling action (including default case for 'submit' action)
      eBillData = this.ebillData;
    }

    if (this.form.valid && this.validateEmailAndMobile()) {
      this.invoice_no = null;
      this.form.get("trans_no")!.setValue(this.invoice_no);

      this.transDataObj = this.form.value;
      this.transDataObj.items_details = validItems;
      this.transDataObj.soIdArr = [...new Set(this.soIdArr)];
      this.transDataObj.ledger_id = 0;
      this.transDataObj.table_id = this.table_id;
      this.transDataObj.sale_type = "cash";
      this.transDataObj.orderMode = orderMode;
      this.transDataObj.non_Receivable = this.non_receivable;
      this.transDataObj.eBillData = action == "ebill" ? eBillData : null;
      this.transDataObj.shipping_address1 = this.form?.value.address;

      this.apiService.addInvoice(this.transDataObj).subscribe(
        (result: any) => {
          if (result.success) {
            this.form.value.net_amount = result.net_amount;
            this.form.value.balance_amt = result.balance_amt;
            this.form.value.total_amount = result.total_amount;
            this.form.value.balance = result.balance;
            this.form.value.taxable_amt = result.taxable_amt;
            this.transDataObj.transaction_id = result.transaction_id;
            this.transDataObj.display_trans_no = result.display_trans_no;
            this.transDataObj.trans_type = "Invoice";
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.initializeFormGroup();
            if (action === "ebill" || action === "print") {
              this.apiService.downloadPdf(this.transDataObj).subscribe(
                (result: any) => {
                  let blob = new Blob([result], {
                    type: "application/pdf",
                  });
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download =
                    "invoice" + this.transDataObj.display_trans_no + ".pdf";
                  link.click();
                  window.URL.revokeObjectURL(link.href);
                },
                (result: any) => {}
              );
            }
            notyf.success("New Invoice added successfully");
            this.initializeFormGroup();
            // this.getPendingInvoice()
            if (this.form.controls["advance"]?.value) {
              this.initializeFormGroup();
              this.load();
              this.data = undefined;
            }
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.waitforTakeaway = false;
          } else {
            window.scroll(0, 0);
            notyf.error(result.message);
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.waitforTakeaway = false;
          }
        },
        (result: any) => {
          this.initializeFormGroup();
          notyf.error("unable to add invoice");
          this.waitforinvoice = false;
          this.non_receivable = false;
          this.showValidationInput = false;
          this.showValidationDiscount = false;
          this.formSubmitted = false;
          this.validationForm.reset();
          this.validationForm.get("validationCode")!.setValue("");
          this.waitforTakeaway = false;
        }
      );
      if (this.order_tab === "Dine-In") {
        this.panel4.open();
        this.panel3.close();
      }
    } else {
      window.scroll(0, 0);
      this.waitforinvoice = false;
      this.non_receivable = false;
      this.showValidationInput = false;
      this.showValidationDiscount = false;
      this.validationForm.reset();
      this.formSubmitted = false;
      this.validationForm.get("validationCode")!.setValue("");
      this.waitforTakeaway = false;
    }

    // Only change panel states if no validation errors occurred
    if (this.form.valid) {
      if (this.order_tab === "Dine-In") {
        this.panel4.open();
        this.panel3.close();
      }
    }
  }

  onSubmit() {
    const contact_no = this.form?.value?.contact_no;
    const email_id = this.form?.value?.email;
    const customer_name = this.form?.value?.customer_name;

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let isValid = true; // Flag to track form validity

    // Validate contact number length (assuming numeric input)
    if (contact_no && (!/^\d+$/.test(contact_no) ||(contact_no && contact_no.length) < 10)) {
      notyf.error("Contact Number must be at least 10 digits long");
      isValid = false;
    }
    if (email_id && !emailRegex.test(email_id)) {
      notyf.error("Please Provide a Valid Email Address");
      isValid = false;
    }
    if (!customer_name) {
      notyf.error("Customer Name is required");
      isValid = false;
    }

    if (!isValid) {
      return; // Stop further execution if validation fails
    }

    this.saveInvoiceData(
      this.order_tab === "Take Away" ? "takeAway" : "submit"
    );
  }

  SaveAndPrint() {
    this.saveInvoiceData("print");
  }

  SaveAndEbill() {
    const name = this.form?.value?.customer_name;
    const email_id = this.form?.value?.email;
    const contact_no = this.form?.value?.contact_no;

    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check for empty fields
    if (!name) {
      notyf.error("Customer Name is required");
      return; // Stop further execution
    }

    if (!email_id && !contact_no) {
      notyf.error("Please Provide Either Email or Phone Number");
      return; // Stop further execution
    }

    if (email_id && !emailRegex.test(email_id)) {
      notyf.error("Please Provide a Valid Email Address");
      return; // Stop further execution
    }

    // Validate contact number length (assuming numeric input)
    if (contact_no && (!/^\d+$/.test(contact_no) || (contact_no && contact_no.length)  < 10)) {
      notyf.error("Contact Number must be at least 10 digits long");
      return; // Stop further execution
    }

    // If all validations pass, proceed to save invoice data
    if (email_id || contact_no) {
      this.saveInvoiceData("ebill");
      notyf.success("Ebill sent successfully");
    } else {
      notyf.error("Please Provide Either Email or Phone Number");
    }
  }

  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.altKey && event.key === "o") {
      this.togglePopup();
      event.preventDefault();
      setTimeout(() => {
        const searchInput = document.getElementById("popup-search-input");
        searchInput?.focus();
      }, 100);
    } else if (event.ctrlKey && event.altKey && event.key === "m") {
      this.moveToKotList();
      event.preventDefault();
    } else if (event.key === "Escape") {
      this.closePopup();
      event.preventDefault();
    }
  }

  togglePopup() {
    this.showPopup = !this.showPopup;
    if (this.showPopup) {
      setTimeout(() => {
        const searchInput = document.getElementById("popup-search-input");
        searchInput?.focus();
      }, 100);
    }
  }

  searchItems(event: any) {
    const query = event.target?.value.toLowerCase();
    this.filteredOptions = of(this.filterItems(query));
  }

  filterItems(query: string): any[] {
    return this.itemsArr.filter((item: any) =>
      item.item_name.toLowerCase().includes(query)
    );
  }

  selectItem(event: MatAutocompleteSelectedEvent) {
    const selectedItem = event?.option?.value;
    this.addItemToOrderList(selectedItem);
    setTimeout(() => {
      const searchInput = document.getElementById("popup-search-input");
      searchInput?.focus();
    }, 100);
  }

  addItemToOrderList(item: any) {
    const qty = this.form.get("item_qty")?.value || 1;
    const rate = this.form.get("item_rate")?.value || 0;
    const amount = rate * qty;
    this.orderList.push({ ...item, qty, amount });
    this.form.reset({ item_qty: 1, item_rate: 0, item_amt: 0 });
  }

  moveToKotList() {
    this.kotList.push(...this.orderList);
    this.orderList = [];
    this.showPopup = false;
  }

  closePopup(event?: MouseEvent) {
    if (event) {
      const target = event.target as HTMLElement;
      if (!target.closest(".popup-content")) {
        this.showPopup = false;
      }
    } else {
      this.showPopup = false;
    }
  }

  redirectToKeyboard() {
    const currentUrl = this.router.url;
    const newUrl = currentUrl.replace("rst-invoice", "rst-keyboard");
    this.router.navigateByUrl(newUrl);
  }

  validateEmailAndMobile() {
    const email = this.form?.value.email;
    const contact_no = this.form?.value.contact_no;

    contact_no && contact_no.length !== 10
      ? notyf.error("Contact number must be exactly 10 digits")
      : null;

    // if (!contact_no && !email) {
    //   return true; // Prevent further execution if validation fails
    // }

    const customername = this.form?.value.customer_name;
    if (!customername) {
      notyf.error("Customer name is required");
      return false;
    }

    // Email validation (basic regex)
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (email && !emailPattern.test(email)) {
      notyf.error("Please enter a valid email address");
      return false;
    }
    return true;
  }

  checkDueInvoiceObj() {
    if (this.dueInvoiceObj && this.dueInvoiceObj.data && this.dueInvoiceObj.data.length === 0) {
      this.panel4.close();
    } else {
      this.panel4.open();
    }
  }
}
